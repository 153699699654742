import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {insertData} from '../helpers'


const GraficaGeneral =( { titulo } )=>{
  
  const options_general = {  
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 200
    },
    credits: {
      enabled: false
    },
    exporting:{
      enabled: false
    },

    title: {
        text: `<b style="font-size: 15px">${titulo}</b>`,
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: false
          },
          showInLegend: true
      }
    },
    series: [{
        name: 'Total',
        innerSize: '60%',
        data: [
            {
              y: 100,
              name: "En tiempo",
              color: "#8ddc39"
            },
            {
              y: 0,
              name: "Retrasado",
              color: "#ff9800"
            },
        ]
    }]
  }
  

  return(
      <HighchartsReact
        highcharts={Highcharts}
        options={options_general}
      />
  )
}

export default GraficaGeneral