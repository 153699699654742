import React, { useState } from 'react'
import Mensaje from '../../../Mensaje'
import { insertDataJson } from '../../../helpers'

const Formulario = ({ locacion_id, getPreguntas }) =>{
  const [pregunta, setPregunta] = useState({
    pregunta: ''
  })
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const actualizaState = e =>{
    setPregunta({
      ...pregunta,
      [e.target.name] : e.target.value
    })
  } 



  const submitFormulario = async e =>{
    e.preventDefault();
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Guardando....',
      etiqueta: 'primary'
    });
    let ruta = 'pregunta-equipo';
    let datos = {
      pregunta: pregunta.pregunta,
      locacion_id: locacion_id
    }
    const send = await insertDataJson(ruta,datos);
    console.log(send)
    if(send.code === 201){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Se ha guardado correctamente',
        etiqueta: 'success'
      });
      getPreguntas();
      return;
    }
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Ha ocurrido algo y no se guardó la pregunta',
      etiqueta: 'error'
    })
    console.log(pregunta);
  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-body">
          <form className=""
            onSubmit={submitFormulario}
          > 
              <label>Pregunta(*)</label>
              <input  
                type="text" 
                className="mb-2 form-control-sm form-control" 
                name = "pregunta"
                value = {pregunta.pregunta}
                onChange = {actualizaState}
                required
                />
                { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
              <div style={{ marginTop: 10 }}></div>
              <button className="mt-1 btn btn-primary">Guardar</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formulario