import React, { useState } from 'react'
import { insertDataJson, updateItem } from '../helpers'
import Mensaje from '../Mensaje'

const Formulario = ({getPuestos, setPuesto, puesto, setActualizar, actualizar}) =>{
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const { desc_puesto } = puesto;

  const actualizaState = e =>{
    setPuesto({
      ...puesto,
      [e.target.name] : e.target.value
    })
  } 

  const reiniciarForm = (e) =>{
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Se ha guardado correctamente',
      etiqueta: 'success'
    })
    setPuesto({
      desc_puesto: '',
    })
    getPuestos();
  }

  const submitFormulario = async e =>{
    e.preventDefault();

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Guardando....',
      etiqueta: 'primary'
    })

    if(desc_puesto ===''){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Todos los campos(*) son obligatorios',
        etiqueta: 'error'
      })
      return;
    }

    let ruta = 'puesto';
    if(!actualizar){
      //Guardar
      let datos = {
        desc_puesto: puesto.desc_puesto,
        valor: puesto.desc_puesto,
      }
      const send = await insertDataJson(ruta,datos);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===201){
        reiniciarForm();
      }
    }else{
      //Actualizar
      let datos = {
        desc_puesto: puesto.desc_puesto,
        valor: puesto.desc_puesto,
      }
      const send = await updateItem(ruta+'/'+puesto.id,datos);
      console.log(send);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===200){
        setActualizar(false);
        const user =  await JSON.parse(localStorage.getItem('ACEROS_USER'));
        reiniciarForm();
      }
    }

  }

  return(
    <div className="col-md-5">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            PUESTOS
          </div>
        </div>
        <div className="card-body">
          { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          <form className=""
            onSubmit={submitFormulario}
          > 
            <label>Descripción(*)</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "desc_puesto"
              value = {desc_puesto}
              onChange = {actualizaState}
              />
              {
                actualizar ?
                <button className="mt-1 btn btn-warning">Actualizar puesto</button>
                :
                <button className="mt-1 btn btn-primary">Guardar puesto</button>
              }
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formulario