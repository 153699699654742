import React from 'react'
import DataTable from 'react-data-table-component'
import Mensaje from '../../../Mensaje'

const Tabla = ({ bitacora, verDetalle, reporteIncidete }) =>{

  const numIncidentes = (fecha) =>{
    const num = reporteIncidete.filter(item => item.fecha === fecha)
    return num.length
  }
  const columnas = [
    {
      name: 'Locacion',
      selector : 'nombre_locacion',
    },
    {
      name: 'Fecha',
      selector : 'fecha',
      sortable: true
    },
    {
      name: 'No. Incidentes',
      cell: (row) => <div>
                        { 
                          numIncidentes(row.fecha)
                        }
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: true
    }, 
    {
      name: 'No. Rondas',
      selector : 'total',
    },
    {
      name: 'Detalle',
      cell: (row) => <div>
                        <button onClick={()=>verDetalle(row)} type="button" className="btn btn-outline-primary btn-sm" data-tip="Detalle">
                          <li className="fa fa-eye"></li> 
                        </button>
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },  
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            RONDAS
          </div>
        </div>
        <div className="card-body">
          <DataTable
              columns= {columnas}
              noHeader
              data={bitacora}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla