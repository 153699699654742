import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {insertData} from '../helpers'


const GraficaLocacion =( { total, nombre, num_sospechoso } )=>{
  
  const options_general = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: 200
    },
    credits: {
      enabled: false
    },
    exporting:{
      enabled: false
    },
    title: {
        text: '<b style="font-size: 30px">'+ parseInt(total) +'</b>',
        align: 'center',
        verticalAlign: 'middle',
        y: 50
    },
    subtitle:{
      text: nombre,
      align: 'center',
      verticalAlign: 'middle',
      y: 65
    },
    
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>({point.y})',
            distance: 10,
            filter: {
                property: 'y',
                operator: '>',
                value: 1
                }
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            size: '150%'
        }
    },
    series: [{
        name: 'Total',
        type: 'pie',
        innerSize: '60%',
        data: [
            {
              y: parseInt(num_sospechoso),
              name: "Sospechoso",
              color: "#ff9800"
            },
            {
              y: parseInt(total) - parseInt(num_sospechoso),
              name: "Sin sospecha",
              color: "#00bcd4"
            }
        ]
    }]
  }
  

  return(

    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={options_general}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraficaLocacion