import React from 'react'
import DataTable from 'react-data-table-component'
import { CSVLink } from "react-csv";
import Mensaje from '../Mensaje'

const Tabla = ({asistencias, mostrarmensaje2 }) =>{

  const columnas = [
    {
      name: 'Nombre',
      selector : 'nombre_completo',
    },
    {
      name: 'Puesto',
      selector : 'desc_puesto',
    },
    {
      name: 'Fecha',
      selector : 'fecha',
    },
    {
      name: 'Hora',
      selector : 'hora',
    }
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  const headers_asistencia = [
    { label: "Nombre Completo", key: "nombre_completo" },
    { label: "Puesto", key: "desc_puesto" },
    { label: "Cliente", key: "nombre_comercial" },
    { label: "Locacion", key: "nombre_locacion" },
    { label: "Fecha", key: "fecha" },
    { label: "Hora", key: "hora" },
  ];


  return(
    <div className="col-md-7">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            LISTA DE ASISTENCIA
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className ="col-md-6 offset-md-6 text-right">
              {
                asistencias.length > 0 ?
                  <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
                    <li className="fa fa-cloud-download"></li>  
                    <CSVLink data={asistencias} filename={"ListaAsistencia.csv"} headers={headers_asistencia} style={{color: 'white'}}>
                      Descargar lista</CSVLink>
                  </button> 
                :
                null
              }
            </div>
          </div>
          { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
          <DataTable
              columns= {columnas}
              noHeader
              data={asistencias}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla