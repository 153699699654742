import React from 'react'
import Tabla from './Tabla'
import { updateItem } from '../../../helpers'

const Preguntas = ({ datos, eliminaRonda, getRondas }) =>{

  const actualizarStatus = async ( status, id) =>{
    let datos = {
      status: 0
    }
    if(parseInt(status) === 0){
      datos = {
        status: 1
      }
    }

    let ruta = 'qr-locacion';
    const send = await updateItem(ruta+'/'+id,datos);
    console.log(send)
    if(send.code === 200){
      getRondas();
      return;
    }
  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            RONDAS PROGRAMADAS
          </div>
        </div>
        <div className="card-body">
          {
            datos.map(item=>(
              <div>
                <h5>{ item.area }
                {
                  parseInt(item.status) === 0 ?
                  <button type="button" onClick = {()=>actualizarStatus(item.status, item.id)} className="btn btn-default btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#">
                    <li className="fa fa-eye"></li>
                  </button>
                  :
                  <button type="button" onClick = {()=>actualizarStatus(item.status, item.id)} className="btn btn-default btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#">
                    <li style={{ color: 'red' }} className="fa fa-eye-slash"></li>
                  </button>
                  }
                </h5>
                  <Tabla 
                    rondas = { item.ronda }
                    eliminaRonda = { eliminaRonda }
                  />
                <hr/>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Preguntas