import React, { useEffect, useState, useContext } from 'react'
import { deleteItem, getData, insertDataJson } from '../../../helpers'
import Guardia from './Guardia'
import { AppContext } from '../../../contextApp'

const Bitacora = () =>{  
  const { dominio } = useContext(AppContext);
  const [usuarios, setUsuarios]= useState([]);
  const [clientes, setClientes] = useState([]);
  const [locaciones, setLocaciones] = useState([]);
  const [filtro, setFiltro] = useState({
    locacion_id: 0
  });


  const getGuardias = async()=>{
    let ruta = 'usuario'
    const send = await getData(ruta);
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    let usuarios = [];
    if(send.data){
      if(parseInt(usr.cliente_id) === 0){
        usuarios = send.data.filter(item => parseInt(item.jefe_grupo) === 1);
        setUsuarios(usuarios);
        return;
      }
      usuarios = send.data.filter(item => parseInt(item.jefe_grupo) === 1 && item.cliente_id == usr.cliente_id);
      setUsuarios(usuarios);
      return;
    }
  }

  
  const verDetalle = (data) =>{
    window.location = `/guardias/detalle-guardia?usuario=${data.id}`;
  }


  useEffect(()=>{
    getGuardias();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <h3>TOTAL DE GUARDIAS: {usuarios.length} </h3>
        {
          usuarios ?
          usuarios.map(item=>(
            <Guardia 
              guardia = { item }
              verDetalle = { verDetalle }
            />
          ))
          :
          null
          
        }
      </div>
    </div>
  )
}

export default Bitacora