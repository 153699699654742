import React from 'react'
import TablaConsignas from './TablaConsignas'

const Preguntas = ({ datos }) =>{

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            CONSIGNAS
          </div>
        </div>
        <div className="card-body">
          {
            datos.map(item=>(
              <div>
                <h5>{ item.area }</h5>
                  <TablaConsignas 
                    consignas = { item.consignas }
                    key = { item.check_ronda_programada_id }
                  />
                <hr/>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Preguntas