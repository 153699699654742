import React, { useEffect, useState, useContext } from 'react'
import { getData, deleteItem } from '../helpers'
import Formulario from './Formulario'
import Tabla from './Tabla'
import { AppContext } from '../contextApp'

const Puestos = () =>{  
  const { dominio } = useContext(AppContext);
  const [puestos, setPuestos]= useState([]);
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [puesto, setPuesto] = useState({
    desc_puesto: '',
    valor: ''
  });
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const [imagen, setImagen]= useState(null);

  const getPuestos = async()=>{
    const send = await getData('puesto');
    console.log(send);
    if(send.data){
      setPuestos(send.data)
      return;
    }
  }
  
  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    setPuesto(data);
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('puesto/'+data.id);
    console.log(send);
    if(send.code===423){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: send.error,
        etiqueta: 'error'
      })
    }
    if(send.code===200){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado el puesto',
        etiqueta: 'success'
      })
      setBotonEliminar(false);
      getPuestos();
    }
  }

  useEffect(()=>{
    getPuestos();

  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <Formulario 
          getPuestos = {getPuestos}
          setPuesto = {setPuesto}
          puesto = {puesto}

          setActualizar = {setActualizar}
          actualizar = {actualizar}

          setImagen = {setImagen}
          imagen = {imagen}
        />
        <Tabla 
          mostrarmensaje2 = {mostrarmensaje2}

          puestos = {puestos}
          actualiza = {actualiza}

          botoneliminar = {botoneliminar}
          setBotonEliminar = {setBotonEliminar}

          eliminaItem = {eliminaItem}
        />
      </div>
    </div>
  )
}

export default Puestos