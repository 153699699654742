import React, { Fragment, useState, useEffect } from 'react'

 const Sidebar = ( { active } ) =>{
  const [usuario, setUsuario] = useState({});

  const getUsuario = async () =>{
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    if(!usr){
      window.location = '/login'
    }
    setUsuario(usr);
  }
  
  useEffect(() => { 
    getUsuario();
  }, [])

   return(
    <Fragment>
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-left ms-3" id="sidenav-main">
        <div className="sidenav-header">
          <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute right-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
          <a className="" href= { 
              parseInt(usuario.cliente_id) === 0 ?  
                "/panel" 
              :  
                parseInt(usuario.cliente_id) > 0 ?
                  "/dashboard-cliente"
                : 
                "/guardias" }>
            <img style={{ width: 60, height: 60 }} src="https://qhse.sippsa.mx/logo_sippsa_azul.png" alt="..." />
            <span className="ms-1 font-weight-bold">Grupo Sippsa</span>
          </a>
        </div>
        <hr className="horizontal dark mt-0" />
        <div className="collapse navbar-collapse  w-auto" id="sidenav-collapse-main">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className= { active === 6 ? "nav-link  active" : "nav-link" } href= { parseInt(usuario.cliente_id) === 0 ?  "/guardias/dashboard" :  "/guardias/dashboard-cliente" }>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <li style={{ color:  active === 6 ? 'white' : 'gray' }} className="fa fa-tachometer-alt"></li>
                </div>
                <span className="nav-link-text ms-1">Dashboard</span>
              </a>
            </li>
            <li className="nav-item">
              <a className= { active === 0 ? "nav-link  active" : "nav-link" } href= { parseInt(usuario.cliente_id) === 0 ?  "/guardias/bitacora-rondas" :  "/guardias/bitacora-rondas" }>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <li style={{ color:  active === 0 ? 'white' : 'gray' }} className="fa fa-clipboard-check"></li>
                </div>
                <span className="nav-link-text ms-1">Bitacora rondas</span>
              </a>
            </li>
            <li className="nav-item">
              <a className= { active === 1 ? "nav-link  active" : "nav-link" } href="/guardias/consignas">
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <li style={{ color:  active === 1 ? 'white' : 'gray' }} className="fa fa-check-square"></li>
                </div>
                <span className="nav-link-text ms-1">Consignas</span>
              </a>
            </li>
            {
              usuario.admin === 'true' ?
              <Fragment>
                <li className="nav-item">
                  <a className= { active === 2 ? "nav-link  active" : "nav-link" } href="../guardias/rondas-programadas">
                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <li style={{ color:  active === 2 ? 'white' : 'gray' }} className="fa fa-clock"></li>
                    </div>
                    <span className="nav-link-text ms-1">Programar rondas</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className= { active === 7 ? "nav-link  active" : "nav-link" } href="../guardias/rondas-abiertas">
                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <li style={{ color:  active === 7 ? 'white' : 'gray' }} className="fa fa-qrcode"></li>
                    </div>
                    <span className="nav-link-text ms-1">Rondas abiertas</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className= { active === 5 ? "nav-link  active" : "nav-link" } href="/guardias/pregunta-equipo">
                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <li style={{ color:  active === 5 ? 'white' : 'gray' }} className="fa fa-tools"></li>
                    </div>
                    <span className="nav-link-text ms-1">Equipo/Materiales</span>
                  </a>
                </li>
              </Fragment>
              : null
            }
            <li className="nav-item">
              <a className= { active === 3 ? "nav-link  active" : "nav-link" } href="../guardias/guardias">
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <li style={{ color:  active === 3 ? 'white' : 'gray' }} className="fa fa-list-alt"></li>
                </div>
                <span className="nav-link-text ms-1">Guardias</span>
              </a>
            </li>
            {/* <li className="nav-item">
              <a className= { active === 4 ? "nav-link  active" : "nav-link" } href="../guardias/reporte">
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <li style={{ color:  active === 4 ? 'white' : 'gray' }} className="fa fa-file-contract"></li>
                </div>
                <span className="nav-link-text ms-1">Reportes</span>
              </a>
            </li> */}
            <li className="nav-item mt-3">
              <hr/>
            </li>
            <li className="nav-item">
              <a className="nav-link  " href="../Login">
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                <li style={{ color: 'gray' }} className="fa fa-sign-out-alt"></li>
                </div>
                <span className="nav-link-text ms-1">Cerrar sesión</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
    </Fragment>    
   )
 }

 export default Sidebar