import React, { useEffect, useState, useContext } from 'react'
import { getData, insertDataJson } from '../../../helpers'
import Tabla from './Tabla'
import Mensaje from '../../../Mensaje';

const RondasAbiertas = () =>{  
  const [rondas, setRondas]= useState([]);
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getRondasAbiertas = async()=>{
    const send = await getData('rondas-abiertas');
    if(send.data){
        setRondas(send.data)
      return;
    }
  }

  const cerrarRonda = async (id) =>{
    console.log(id)
    const send = await insertDataJson(`cerrar-ronda/${id}`);
    if(send.data){
        getRondasAbiertas();
        setMostrarMensaje({
            mostrar: true,
            mensaje: 'Se ha actualizado correctamente',
            etiqueta: 'success'
          });
        setTimeout(()=>{
            setMostrarMensaje({
                mostrar: false,
                mensaje: '',
                etiqueta: ''
              });
        },3000)
      return;
    }
    setMostrarMensaje({
        mostrar: true,
        mensaje: 'Ha ocurrido algo y no se actualizó',
        etiqueta: 'error'
      });
  }

  useEffect(()=>{
    getRondasAbiertas();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
      { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
        <Tabla 
          rondas = {rondas}
          cerrarRonda = {cerrarRonda}
        />
      </div>
    </div>
  )
}

export default RondasAbiertas