import React, { useEffect, useState, useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { AppContext } from './contextApp'

import Header from './Template/Header'
import SidebarGuardias from './Template/SidebarGuardias'
import Footer from './Template/Footer'

import Bitacora from './Operaciones/Guardias/Bitacora/Bitacora'
import BitacoraDetalle from './Operaciones/Guardias/Bitacora/BitacoraDetalle'

import Consignas from './Operaciones/Guardias/Consignas/Consignas'
import RondasProgramada from './Operaciones/Guardias/RondaProgramada/RondaProgramada'
import RondasAbiertas from './Operaciones/Guardias/RondasAbiertas/RondasAbiertas';

import UsuarioGuardia from './Operaciones/Guardias/UsuariosGuardia/UsuarioGuardia'
import GuardiaDetalle from './Operaciones/Guardias/UsuariosGuardia/GuardiaDetalle'

import Reporte from './Operaciones/Guardias/Reporte/Reporte'
import ReporteDetalle from './Operaciones/Guardias/Reporte/ReporteDetalle'

import PreguntaEquipo from './Operaciones/Guardias/EquipoMateriales/EquipoMateriales'

import Dashboard from './Operaciones/Guardias/Dashboard/Dashboard';
import DashboardCliente from './Operaciones/Guardias/Dashboard/DashboardCliente'



const Main = (props) => {
  const { ruta, setRuta } = useContext(AppContext);
  const [active, setActive] = useState(0);
  const [despliega, setDespliega] = useState(0);

// const validar = async () =>{
//   const isAuthenticated = await getCookie('STORAGE_KEY');
//   if(!isAuthenticated){
//     props.history.push("/login");
//   }
// }

  useEffect(() => { 

//    validar();

    if(window.location.pathname==="/guardias/bitacora-rondas" || window.location.pathname==="/guardias/detalle-bitacora"){
      setActive(0)
      setRuta('Bitacora Rondas');
    }else if(window.location.pathname==="/guardias/consignas"){
      setActive(1)
      setRuta('Consignas');
    }else if(window.location.pathname==="/guardias/rondas-programadas"){
      setActive(2)
      setRuta('Programar Rondas');
    }else if(window.location.pathname==="/guardias/rondas-abiertas"){
      setActive(7)
      setRuta('Rondas abiertas');
    }
    else if(window.location.pathname==="/guardias/reporte" ||  window.location.pathname==="/guardias/detalle-reporte-ronda"){
      setActive(4)
      setRuta('Reportes');
    }
    else if(window.location.pathname==="/guardias/pregunta-equipo"){
      setActive(5)
      setRuta('Equipo, Materiales y Herramientas');
    }
    else if(window.location.pathname==="/guardias/dashboard"){
      setActive(6)
      setRuta('Dashboard Rondines');
    }
    else if(window.location.pathname==="/guardias/dashboard-cliente"){
      setActive(6)
      setRuta('Dashboard Rondines');
    }
    else if(window.location.pathname==="/guardias/guardias"){
      setActive(3)
      setRuta('Guardias');
    }
    else if(window.location.pathname==="/guardias/detalle-guardia"){
      setActive(3)
      setRuta('Detalles del guardia');
    }
}, [])


  return (
    <Router>
      <SidebarGuardias 
        active = {active}
      />
        <main class="main-content mt-1 border-radius-lg">
            <Header ruta = { ruta } />
            <div className="app-main"> 
                <div className="app-main__outer">
                    <Switch>
                      <Route path="/guardias/dashboard-cliente" component={DashboardCliente} />
                      <Route path="/guardias/dashboard" component={Dashboard} />
                      <Route path="/guardias/pregunta-equipo" component={PreguntaEquipo} />
                      <Route path="/guardias/detalle-guardia" component={GuardiaDetalle} />
                      <Route path="/guardias/guardias" component={UsuarioGuardia} />
                      <Route path="/guardias/detalle-reporte-ronda" component={ReporteDetalle} />
                      <Route path="/guardias/reporte" component={Reporte} />
                      <Route path="/guardias/rondas-programadas" component={RondasProgramada} />
                      <Route path="/guardias/rondas-abiertas" component={RondasAbiertas} />
                      <Route path="/guardias/consignas" component={Consignas} />
                      <Route path="/guardias/detalle-bitacora" component={BitacoraDetalle} />
                      <Route path="/guardias/bitacora-rondas" component={Bitacora} />
                      <Route path="/guardias/" component={Bitacora} />
                    </Switch>
                  <Footer />
                </div>
            </div>
        </main>
    </Router>
  );
}

export default Main;
