import React, { useEffect, useState, useContext } from 'react'
import { getData, insertDataJson } from '../../../helpers'
import { useLocation } from 'react-router-dom'

import TablaDetalle from './TablaDetalle'
import ReporteIncidente from './ReporteIncidente'
import TablaTiempoRondas from './TablaTiempoRondas'
import TiempoRondas from './TiempoRondas'
import Consignas from './Consignas'

const Bitacora = () =>{  
  const [bitacora, setBitacora]= useState([]);
  const [bitacora1, setBitacora1]= useState([]);
  const [consignas, setConsignas]= useState([]);
  const [reporteincidente, setReporteIncidente] = useState([]);
  const [tiemposrecorrido, setTiempoRecorrido] = useState([]);
  const [filtro, setFiltro] = useState({
    locacion_id: 0
  });

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const getBitacora = async()=>{
    let fecha = query.get("fecha");
    let locacion_id = query.get("locacion_id");
    let ruta = 'historial-ronda-locacion'
    let datos = {
      fecha: fecha,
      locacion_id: locacion_id
    }
    const send = await insertDataJson(ruta,datos);
    console.log(send);
    if(send.data){
      setBitacora(send.data)
      return;
    }
  }

  const getConsignas = async(clave)=>{
    let fecha = query.get("fecha");
    let locacion_id = query.get("locacion_id");
    let consignas = [];
    let consigna = []
    let ruta = 'checks-rondas-detalle'
    let datos = {
      fecha: fecha,
      locacion_id: locacion_id
    }
    const send = await insertDataJson(ruta,datos);
    if(send.data){
       send.data.map(item=>{
        consigna = item.consignas.filter(item2=> item2.clave === clave)
        if(consigna.length > 0){
          consignas.push(item);
        }
       })
      setConsignas(consignas)
      return;
    }
  }


  const getBitacora1 = async()=>{
    let fecha = query.get("fecha");
    let locacion_id = query.get("locacion_id");
    let ruta = 'bitacora-detalle'
    let datos = {
      fecha: fecha,
      locacion_id: locacion_id
    }
    const send = await insertDataJson(ruta,datos);
    console.log(send);
    if(send.data){
      setBitacora1(send.data)
      return;
    }
  }

  const getTiemposAreas = async( clave )=>{
    let ruta = 'bitacora/'+clave
    const send = await getData(ruta);
    console.log(send);
    if(send.data){
      setTiempoRecorrido(send.data)
      getConsignas(clave);
      return;
    }
  }

  const getReporteIncidente = async()=>{
    let fecha = query.get("fecha");
    let locacion_id = query.get("locacion_id");
    let ruta = 'reporte-incidente-fotos'
    let datos = {
      fecha: fecha,
      locacion_id: locacion_id
    }
    const send = await insertDataJson(ruta,datos);
    console.log(send);
    if(send.data){
      setReporteIncidente(send.data)
      return;
    }
  }

  useEffect(()=>{
    getBitacora();
    getBitacora1();
    getReporteIncidente();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        {/* <TiempoRondas
          bitacora = { bitacora1 }
        /> */}
        <TablaTiempoRondas 
          bitacora = { tiemposrecorrido }
        />
        <TablaDetalle 
          bitacora = { bitacora }
          getTiemposAreas = { getTiemposAreas }
        />
        <Consignas 
          datos = { consignas }
        />
        <ReporteIncidente
          reporteincidente = { reporteincidente }
        />
      </div>
    </div>
  )
}

export default Bitacora