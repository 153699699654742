import React, {createContext, useState} from 'react'

export const AppContext = createContext();

const AppProvider = (props) =>{
  const [dominio, setDominio] = useState('http://localhost:8000');
  const [modaleliminar, serModalEliminar] = useState({
    titulo: '',
    desc: '',
    aceptar: false
  });

  const[ruta, setRuta] = useState('');

  var fecha = new Date(); 
  var mes = fecha.getMonth()+1; 
  var dia = fecha.getDate(); 
  var ano = fecha.getFullYear();
  if(dia<10)
    dia='0'+dia; 
  if(mes<10)
    mes='0'+mes 
  fecha = ano+"-"+mes+"-"+dia;



  return(
    <AppContext.Provider
      value={{
        dominio,
        modaleliminar,
        serModalEliminar,
        ruta,
        setRuta
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppProvider
