import React, { Fragment } from 'react'

import GraficaRendimientoGuardia  from '../../../Graficas/GraficaRendimientoGuardia'

const DatosGuardia = ({ usuario }) =>{
  return(
    <Fragment>
      <div className="col-md-4">
        <div className="card mb-3">
          <div className="card-header-tab card-header">
            <div className="card-header-title">
              DATOS GUARDIA
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className ="foto-perfil">                
              </div>          
              <p style={{ marginBottom: 0, fontSize: 14 }}>Nombre: { usuario ? usuario.name : '-' }</p>
              <p style={{ marginBottom: 0, fontSize: 14 }}>Usuario: { usuario ? usuario.email : '-' }</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card mb-3">
          <div className="card-body">
            <GraficaRendimientoGuardia titulo ="Cumplimiento consignas" />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card mb-3">
          <div className="card-body">
            <GraficaRendimientoGuardia  titulo ="Rendimiento rondas" />
          </div>
        </div>
      </div>
  </Fragment>
  )
}

export default DatosGuardia