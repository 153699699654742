import React from 'react'
import DataTable from 'react-data-table-component'
import Mensaje from '../../../Mensaje'

const Tabla = ({ bitacora }) =>{

  const columnas = [
    {
      name: 'Guardia',
      cell: (row) => <div style={{ width: 100 }}>
                       <b> {row.user.name} </b>
                    </div>,
      width: 150
    },
    {
      name: 'Area',
      selector : 'area',
      width: 50
    },
    {
      name: 'Fecha',
      selector : 'fecha_check',
      sortable: true,
      width: 50
    },
    {
      name: 'Hora Check',
      cell: (row) => <div>
                        <label>{row.hora_check}</label>
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Descripción',
      selector : 'descripcion',
      wrap: true
    }
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            RONDAS
          </div>
        </div>
        <div className="card-body">
          <DataTable
              columns= {columnas}
              noHeader
              data={bitacora}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla