import React from 'react'
import DataTable from 'react-data-table-component'

const Tabla = ({ consignas }) =>{

  const columnas = [
    {
      name: 'Pregunta',
      selector : 'pregunta',
    }, 
    {
      name: 'Respuesta',
      selector : 'respuesta',
    }
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-body">
          <DataTable
              columns= {columnas}
              noHeader
              data={consignas}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla