import React, { useState } from 'react'
import DataTable from 'react-data-table-component'
import { CSVLink } from "react-csv";

const Tabla = ({ bitacora }) =>{
  const columnas = [
    {
      name: 'Registro',
      selector : 'hora',
    },
    {
      name: 'Área',
      cell: (row) => <div>
                        {
                          <div style={{ float: 'center', justifyContent: 'center', marginBottom: 10, lineHeight: 1.1, backgroundColor: 'green', borderRadius: 10, color: 'white', textAlign: 'center', padding: 5, fontSize: 12 }}>{ row.area }</div>
                        }
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Programado (Recorrido)',
      selector : 'programado',
    },
    {
      name: 'Realizado',
      cell: (row) => <div style={{ color: parseInt(row.status_recorrido) === 1 ? 'red' : '' }}>
                        {
                          parseFloat(row.realizado) < 1 ?
                            '<1min'
                          :
                            row.realizado
                        }
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: true,
      width: 50
    },
    {
      name: 'Programado (Estancia)',
      selector : 'programado_estancia',
      sortable: true,
      width: 50
    },
    {
      name: 'Realizado',
      cell: (row) => <div style={{ color: parseInt(row.status_estancia) === 1 ? 'red' : '' }}>
                        {
                          row.realizado_estancia
                        }
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: true,
      width: 50
    }
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title row">
            <div className="col-md-6">
              TIEMPOS DEL RECORRIDO
            </div>
          </div>
        </div>
        <div className="card-body">
          <DataTable
              columns= {columnas}
              noHeader
              data={bitacora}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla