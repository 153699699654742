import React, { useState } from 'react'
import { insertDataJson, updateItem } from '../helpers'
import Mensaje from '../Mensaje'

const Filtros = ({personal, personals, setPersonals, setPersonal, clientes, locaciones, getLocaciones, setPersonalFiltrado}) =>{
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const {     
    locacion_id,
    cliente_id } = personal;

  const actualizaState = e =>{

    setPersonal({
      ...personal,
      [e.target.name] : e.target.value
    })

    if(e.target.name === 'cliente_id'){
      const personal_filtrado = personals.filter(item => parseInt(item.cliente_id) === parseInt(e.target.value))
      setPersonalFiltrado(personal_filtrado);
      getLocaciones(e.target.value)
    }

    if(e.target.name === 'locacion_id'){
      const personal_filtrado = personals.filter(item => parseInt(item.locacion_id) === parseInt(e.target.value))
      setPersonalFiltrado(personal_filtrado);
      getLocaciones(e.target.value)
    }
  } 

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-body">
          { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          <div className="row">
            <div className="col-md-6">
              <label>Cliente:</label>
              <select 
                className="form-control"
                name="cliente_id" 
                value = {cliente_id}
                onChange = {actualizaState}

                >
                <option value = "0">-- Seleccione un cliente --</option>
                {
                    clientes ?
                    clientes.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.nombre_comercial}
                      </option>
                    ))
                  :null
                }
              </select>
            </div>
            <div className="col-md-6">
              <label>Locacion:</label>
              <select 
                className="form-control"
                name="locacion_id" 
                value = {locacion_id}
                onChange = {actualizaState}

                >
                <option value = "0">-- Seleccione una locacion --</option>
                {
                    locaciones ?
                    locaciones.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.nombre_locacion}
                      </option>
                    ))
                  :null
                }
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Filtros