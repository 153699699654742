import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ContainerLogin from './Login/ContainerLogin'
import ContainerGuardias from './ContainerGuardias'
import Panel from './Home/Home'
import Container from './Container'

const Main = () => {
  return (
    <Router>
      <main>
          <Switch>
            <Route path="/guardias/"  component={ContainerGuardias} />
            <Route path="/login"  component={ContainerLogin} />
            <Route path="/panel"  component={Panel} />
            <Route path="/"  component={Container} />
          </Switch>
      </main>
    </Router>

  );
}

export default Main;
