import React, { useState } from 'react'
import Select from 'react-select';
import { insertDataJson } from '../helpers'
import Mensaje from '../Mensaje'

const Formulario = ({ personals, setAsistencias, setMostrarMensaje2, user }) =>{
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const [fechas, setFechas] = useState({
    f_ini: '',
    f_fin: '',
    personal_id: 0,
    cliente_id: 0
  });
  const [validar, setValidar] = useState(false);

  const actualizaState = e =>{
    setFechas({
      ...fechas,
      [e.target.name] : e.target.value,
      cliente_id: user.cliente_id
    })
  } 

  async function setPersonal (personal) {
    setFechas({
      ...fechas,
      personal_id: personal.value,
    })
  }

  const submitFormulario = async e =>{
    e.preventDefault();
    
    setMostrarMensaje2({
      mostrar: true,
      mensaje: 'Consultando...',
      etique: 'primary'
    })
    setValidar(false);
    const send = await insertDataJson('asistencia',fechas);
    console.log(send);
    if(send.code===200){
      setAsistencias(send.data);
    }
    setMostrarMensaje2(false);
    console.log(fechas);
  }



  return(
    <div className="col-md-5">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            PERSONAL
          </div>
        </div>
        <div className="card-body">
          { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          <form className=""
            onSubmit={submitFormulario}
          > 
            <label>Fecha inicio:</label>
            <input  
                type="date" 
                className="mb-2 form-control-sm form-control" 
                name = "f_ini"
                value = {fechas.f_ini}
                onChange = {actualizaState}
                required
                />
            <label>Fecha final:</label>
            <input  
                type="date" 
                className="mb-2 form-control-sm form-control" 
                name = "f_fin"
                value = {fechas.f_fin}
                onChange = {actualizaState}
                required
                />
              <label>Personal:</label>
                <Select 
                  style = {{ borderColor: validar ? 'red': '' }}
                  options={personals} 
                  onChange={setPersonal}
                />
              <div style={{ marginTop: 10 }}></div>
              <button className="mt-1 btn btn-primary">Consultar</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formulario