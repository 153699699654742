import React from 'react'
import DataTable from 'react-data-table-component'
import Mensaje from '../../../Mensaje'

const Tabla = ({ reporteincidente }) =>{

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            OBSERVACIONES
            {
              reporteincidente.length > 0 ?
              null
              : 
              <p>Consultando...</p>
            }
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            {
            reporteincidente ? 
              reporteincidente.map(item=>(
                <div className ="col-md-12">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          {
                            item.foto1 ?
                              <img style={{ width: '100%' }} src={item.foto1} />
                            : null
                          }
                        </div>
                        <div className="col-md-6">
                          {
                            item.foto2 ?
                              <img style={{ width: '100%' }} src={item.foto2} />
                            : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p><b>Área:</b> {item.area}</p>
                      <p><b>Pregunta:</b> {item.pregunta}</p>
                      <p><b>Prioridad:</b> {item.prioridad}</p>
                      <p><b>Observaciones:</b> {item.observaciones}</p>
                    </div>
                  </div>
                  <hr/>
                </div>
              ))
            : 
              <p>Cargando...</p>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tabla