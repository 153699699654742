import React, { useState, useEffect, Fragment } from 'react'
import { getData, insertDataJson } from '../../../helpers'
import { CSVLink } from "react-csv";
import Pdf from "react-to-pdf";
import GraficaGeneralRondines from '../Graficas/GraficaGeneralRondines'
import GraficaGeneralBarraRondines from '../Graficas/GraficaGeneralBarraRondines'
import GraficaLocacionGeneral from '../Graficas/GraficaLocacionGeneral'
import GraficaLocacionBarraRondonesGeneral from '../Graficas/GraficaLocacionBarraRondinesGeneral'
import GraficaLocacionBarraConsignasGeneral from '../Graficas/GraficaLocacionBarraConsignasGeneral'
import GraficaBarraGeneralMes from '../Graficas/GraficaBarraGeneralMes'

const ref = React.createRef();

const Dashboard = () =>{
  const [cargando, setCargando] = useState(false);
  const [graficames, setGraficaMes] = useState([]);
  const [locaciones, setLocaciones] = useState([]);
  const [graficageneral, setGraficaGeneral] = useState([]);
  const [grafica, setGrafica] = useState([]);
  const [fechas, setFechas] = useState({
    f_ini: '',
    f_fin: ''
  });
  const [validar, setValidar] = useState(false);

  const options = {
    unit: 'in',
    format: [8,14]
  };

  const actualizaState = e =>{
    if([e.target.name] == 'f_fin'){
      if(fechas.f_ini == ''){
        setValidar(true);
      }else{
        setValidar(false);
      }
    }else{
      setValidar(false);
    }
    setFechas({
      ...fechas,
      [e.target.name] : e.target.value
    })
  }


  const getUsuario = async () =>{
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    if(parseInt(usr.cliente_id) !==0 || usr.admin !=='true'){
      window.location = '/login'
    }
  }

  // GRAFICA GUARDIAS

  const getLocaciones = async() =>{
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    const send = await getData('cliente/1');
    console.log(send);
    if(send.code === 200){
      setLocaciones(send.data)
      return;
    }
  }

  const getGraficaGuardiasGeneral = async () =>{
    setCargando(true);
    setGraficaGeneral([]);
    let $datos = {
      empresa_id: 1,
      f_ini: fechas.f_ini,
      f_fin: fechas.f_fin
    }
    const send = await insertDataJson('grafica-guardias',$datos);
    console.log(send);
    if(send){
      setGraficaGeneral(send)
      setCargando(false);
      return;
    }
  }

  const getGraficaMes = async()=>{
    console.log('mes.....')
    setGraficaMes([]);
    let $datos = {
      cliente_id: 16,
      f_ini: fechas.f_ini,
      f_fin: fechas.f_fin
    }
    const send = await insertDataJson('grafica-guardias-general-mes',$datos);
    console.log(send);
    if(send){
      setGraficaMes(send)
      return;
    }
  }

  //GRAFICAS GUARDIAS

  const getTodos = async()=>{
    getGraficaGuardiasGeneral();
    getLocaciones();
    getGraficaMes();
  }

  useEffect( ()=>{
    getTodos(); 
  },[])


  return(
    <div className="container-fluid py-4">
      <div className="row">
        <div className ="col-md-12">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-md-3 offset-md-4">
              <label>Fecha inicio:</label>
              <input  
                style = {{ borderColor: validar ? 'red': '' }}
                type="date" 
                className="mb-2 form-control-sm form-control" 
                name = "f_ini"
                value = {fechas.f_ini}
                onChange = {actualizaState}
                required
                />
            </div>
            <div className="col-md-3">
              <label>Fecha final:</label>
              <input  
                type="date" 
                className="mb-2 form-control-sm form-control" 
                name = "f_fin"
                value = {fechas.f_fin}
                onChange = {actualizaState}
                required
                />
            </div>
            <div className="col-md-2">
              <button style={{marginTop: 25}} onClick={()=>getTodos()} type="button" className="btn btn-success mb-1" >Consultar</button>
            </div>
            <div className ="row text-center" ref={ref} style={{ width: 850, marginTop:20 }}>
              <h5>COMPORTAMIENTO GENERAL {fechas.f_ini} - {fechas.f_fin}</h5>
              <div className ="col-md-6" >
                {
                  graficageneral.cumplimiento_equipo_general ?
                    <GraficaGeneralRondines datos = {graficageneral} /> : null

                }
              </div>
              <div className ="col-md-6" >
                {
                  graficageneral.cumplimiento_equipo_general ?
                    <GraficaGeneralBarraRondines datos = {graficageneral} /> : null

                }
              </div>
              <div className ="col-md-12">
                <div className="row">
                  {
                    locaciones ?
                    locaciones.map(item=>(
                          <Fragment>
                          <div className ="col-md-6" style={{ marginTop: 10 }}>
                            <GraficaLocacionGeneral key={ item.id } datos = {graficageneral} nombre = { item.nombre_comercial } />
                          </div>
                          <div className ="col-md-6" style={{ marginTop: 10 }}>
                            <GraficaLocacionBarraRondonesGeneral datos = {graficageneral} nombre = { item.nombre_comercial }  />
                            <GraficaLocacionBarraConsignasGeneral datos = {graficageneral} nombre = { item.nombre_comercial } />
                          </div>
                        </Fragment>
                      ))
                    : null
                  }
                  <div className ="col-md-12" style={{ marginTop: 10 }}>
                    <GraficaBarraGeneralMes datos = {graficames}  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 text-center" style={{ marginTop: 20 }}>
          <button onClick={()=>getConcentrado()} style={{ fontSize: 10 }} type="button" className="btn btn-primary btn-sm"  >
            <li className="fa fa-check"></li> Generar concentrado
          </button>
        </div> */}
        {/* <div className="col-md-6 text-center" style={{ marginTop: 20 }}>
          <Pdf targetRef={ref} options={options} x={1.1} y={.5} scale={0.7} filename="informe-general">
            {({ toPdf }) => <button type="button" className="btn btn-primary btn-sm" onClick={toPdf}>Generar PDF</button>}
          </Pdf>
        </div> */}
        {/* <div className="col-md-12 text-center">
          { cargando ? 'Generando....' : '' }
          {
            concentrado.length > 0 ?
              <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
                <li className="fa fa-cloud-download"></li>  
                <CSVLink data={concentrado} filename={"BaseDatosQHSECovid.csv"} style={{color: 'white'}}>
                  Descargar base de datos</CSVLink>
              </button> 
            :
            null
          }
        </div> */}
      </div>
    </div>
  )
}

export default Dashboard