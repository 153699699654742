import React, { useEffect, useState, useContext } from 'react'
import { deleteItem, getData, insertData, insertDataJson } from '../../../helpers'
import FiltroLocaciones from '../../../../components/Componentes/FiltroLocaciones'
import Tabla from './Tabla'
import { AppContext } from '../../../contextApp'

const Bitacora = () =>{  
  const { dominio } = useContext(AppContext);
  const [rondas, setRondas]= useState([]);
  const [clientes, setClientes] = useState([]);
  const [locaciones, setLocaciones] = useState([]);
  const [filtro, setFiltro] = useState({
    locacion_id: 0,
    f_ini: '',
    f_fin: ''
  });


  const getRondas = async()=>{
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    let ruta = 'checks-rondas'
    if(filtro.locacion_id > 0){
      const send = await insertDataJson(ruta, filtro);
      console.log(send);
      if(send.data){
        setRondas(send.data)
        return;
      }
    }
  }

  const getClientes = async()=>{
    const send = await getData('cliente');
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    if(parseInt(usr.cliente_id) > 0){
      const cliente = send.data.filter(item=> parseInt(item.id) === parseInt(usr.cliente_id));
      setClientes(cliente);
      return; 
    }
    if(send.data){
      setClientes(send.data)
      return;
    }
  }

  const getLocaciones = async(id)=>{
    const send = await getData('locacion/'+id);
    if(send.data){
      setLocaciones(send.data)
      return;
    }
  }

  const verDetalle = (data) =>{
    window.location = `/guardias/detalle-reporte-ronda?fecha=${data.fecha_check}&locacion_id=${data.locacion_id}`;
  }


  useEffect(()=>{
    getRondas();
    getClientes();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <FiltroLocaciones 
          getDatos = {getRondas}
          setFiltro = {setFiltro}
          filtro = {filtro}
          getLocaciones = {getLocaciones} 
          clientes = {clientes}
          locaciones = {locaciones}
        />
        <Tabla 
          datos = {rondas}
          verDetalle = {verDetalle}
        />
      </div>
    </div>
  )
}

export default Bitacora