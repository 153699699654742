import React from 'react'

const Tabla = ({ locacion }) =>{
  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            DATOS LOCACIÓN
          </div>
        </div>
        <div className="card-body">
            <p>Locación: {locacion.nombre_locacion}</p>
            <p>Dirección: {locacion.direccion_locacion}</p>
        </div>
      </div>
    </div>
  )
}

export default Tabla