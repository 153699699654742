import React, { useState } from 'react'
import { updateItem } from '../../../helpers'
import DataTable from 'react-data-table-component'
import Mensaje from '../../../Mensaje'

const Tabla = ({ reporteincidente }) =>{
  const [plan, setPlan] = useState({
    plan: ''
  });
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const actualizaState = e =>{
    setPlan({
      ...plan,
      [e.target.name] : e.target.value
    })
  } 

  const guardarPlanAccion = async(id) =>{
    let ruta = 'reporte-incidente/'+id;
    const send = await updateItem(ruta,plan);
    console.log(send);
    if(send.data){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Se ha guardado correctamente',
        etiqueta: 'success'
      });
      return;
    }
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Ha ocurrido algo y no se guardó la pregunta',
      etiqueta: 'error'
    })
  } 

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            REPORTE DE INCIDENCIAS
            {
              reporteincidente.length > 0 ?
              null
              : 
              <p>No hay incidencias...</p>
            }
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            {
              reporteincidente ? 
              reporteincidente.map(item=>(
                <div className ="col-md-12">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          {
                            item.foto1 ?
                              <img style={{ width: '100%' }} src={item.foto1} />
                            : null
                          }
                        </div>
                        <div className="col-md-6">
                          {
                            item.foto2 ?
                              <img style={{ width: '100%' }} src={item.foto2} />
                            : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p style={{ marginBottom: 0, fontSize: 15 }}><b>Reportó:</b> {item.name}</p>
                      <p style={{ marginBottom: 0, fontSize: 15 }}><b>Ubicacion:</b> {item.ubicacion}</p>
                      <p style={{ marginBottom: 0, fontSize: 15 }}><b>Razón:</b> {item.razon}</p>
                      <p style={{ marginBottom: 0, fontSize: 15 }}><b>Detalle:</b> {item.detalle}</p>
                      <p style={{ marginTop: 10, fontSize: 15 }}><b>Plan de Acción:</b></p>
                      <textarea 
                        rows="6" 
                        name = "plan"
                        value = {item.plan}
                        className="form-control"
                        onChange = {actualizaState}
                        >
                      </textarea>
                      <button style={{marginTop: 20}} onClick={()=>guardarPlanAccion(item.id)} type="button" className="btn btn-primary mb-1" >Guardar Plan</button>
                      { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
                    </div>
                  </div>
                  <hr/>
                </div>
              ))
              : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tabla