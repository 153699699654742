import React, { useState, useEffect } from 'react'
import { getData, insertDataJson } from '../helpers'
import Pdf from "react-to-pdf";
import GraficaGeneral from '../Graficas/GraficaGeneral'
import GraficaGeneralAtendido from '../Graficas/GraficaGeneralAtendido'
import GraficaLocacion from '../Graficas/GraficaLocacion'
import GraficaOtros from '../Graficas/GraficaOtros'

const ref = React.createRef();

const Dashboard = () =>{
  const [cargando, setCargando] = useState(true);
  const [graficageneral, setGraficaGeneral] = useState([]);
  const [grafica, setGrafica] = useState([]);
  const [fechas, setFechas] = useState({
    f_ini: '',
    f_fin: ''
  });
  const [validar, setValidar] = useState(false);

  const [gpuestos, setGPuestos]= useState([]);
  const [gencuestas, setGEncuestas]= useState([]);
  const [gedades, setGEdades]= useState([]);
  const [gatendido, setGAtendido]= useState([]);
  const [gpersonal, setGPersonal]= useState([]);
  
  const options = {
    unit: 'in',
    format: [8,14]
  };

  const actualizaState = e =>{
    if([e.target.name] == 'f_fin'){
      if(fechas.f_ini == ''){
        setValidar(true);
      }else{
        setValidar(false);
      }
    }else{
      setValidar(false);
    }
    setFechas({
      ...fechas,
      [e.target.name] : e.target.value
    })
  }


  const getGraficaGeneral = async()=>{
    setCargando(true);
    setGraficaGeneral([]);
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    let $datos = {
      id: usr.cliente_id,
      f_ini: fechas.f_ini,
      f_fin: fechas.f_fin
    }
    const send = await insertDataJson('grafica-cliente-general',$datos);
    if(send){
      setGraficaGeneral(send)
      setCargando(false);
      return;
    }
    setCargando(false);
  }

  const getGrafica = async()=>{
    setGrafica([]);
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    let $datos = {
      id: usr.cliente_id,
      f_ini: fechas.f_ini,
      f_fin: fechas.f_fin
    }
    const send = await insertDataJson('grafica-cliente-locacion',$datos);
    if(send){
      setGrafica(send)
      return;
    }
    setCargando(false);
  }

  const getGraficaOtros = async()=>{
    setGPuestos([]);
    setGEncuestas([]);
    setGEdades([]);
    setGPersonal([]);
    setGAtendido([]);
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    let $datos = {
      id: usr.cliente_id,
      f_ini: fechas.f_ini,
      f_fin: fechas.f_fin
    }
    const send = await insertDataJson('grafica-cliente-otros',$datos);
    let dato_grafica_puestos = {};
    let datos_grafica_puestos = [];
    let dato_grafica_encuesta = {};
    let datos_grafica_encuesta = [];
    let dato_grafica_personal = {};
    let datos_grafica_personal = [];
    let total = 0;
    if(send){
      if(send.puestos){
        send.puestos.map(item=>{
          dato_grafica_puestos = {
            y: parseInt(item.y),
            name: item.name,
            total: total
          }
          datos_grafica_puestos.push(dato_grafica_puestos);
        }) 
      }

      if(send.encuestas){
        send.encuestas.map(item=>{
          dato_grafica_encuesta = {
            y: parseInt(item.y),
            name: item.name,
            total: total
          }
          datos_grafica_encuesta.push(dato_grafica_encuesta);
        }) 
      }

      if(send.bajas){
        send.bajas.map(item=>{
          dato_grafica_personal = {
            y: parseInt(item.total_status),
            name: item.status_personal == 0 ? 'Alta' : 'Baja',
            total: total
          }
          datos_grafica_personal.push(dato_grafica_personal);
        }) 
      }

      setGPuestos(datos_grafica_puestos);
      setGEncuestas(datos_grafica_encuesta);
      setGEdades(send.edades);
      setGPersonal(datos_grafica_personal);
      setGAtendido(send.sospechoso_atendido);
      console.log(send.sospechoso_atendido)
      return;
    }
  }

  const getTodos = async()=>{
    getGraficaGeneral();
    getGrafica(); 
    getGraficaOtros(); 
  }

  useEffect( ()=>{
    getGraficaGeneral();
    getGrafica(); 
    getGraficaOtros(); 
  },[])



  return(
<div className="container-fluid py-4">
      <div className="row">
        <div className ="col-md-12">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-md-3 offset-md-4">
              <label>Fecha inicio:</label>
              <input  
                style = {{ borderColor: validar ? 'red': '' }}
                type="date" 
                className="mb-2 form-control-sm form-control" 
                name = "f_ini"
                value = {fechas.f_ini}
                onChange = {actualizaState}
                required
                />
            </div>
            <div className="col-md-3">
              <label>Fecha final:</label>
              <input  
                type="date" 
                className="mb-2 form-control-sm form-control" 
                name = "f_fin"
                value = {fechas.f_fin}
                onChange = {actualizaState}
                required
                />
            </div>
            <div className="col-md-2">
              <button style={{marginTop: 25}} onClick={()=>getTodos()} type="button" className="btn btn-success mb-1" >Consultar</button>
            </div>
            <div className ="row text-center" ref={ref} style={{ width: 850, marginTop:20 }}>
              <h5>INFORME GENERAL {fechas.f_ini} - {fechas.f_fin}</h5>
              <div className ="col-md-6" >
                {
                  graficageneral.length > 0 ?
                    <GraficaGeneral datos = {graficageneral} /> : null

                }
              </div>
              <div className ="col-md-6">
                {
                  gatendido ?
                    <GraficaGeneralAtendido datos = {gatendido} /> : null

                }
              </div>
              <div className ="col-md-12">
                <div className="row">
                  {
                    grafica ?
                      grafica.s_sospecha ?
                        grafica.s_sospecha.map(item=>(
                          <div className ="col-md-6" style={{ marginTop: 10 }}>
                            <GraficaLocacion total = {grafica.total.total} nombre = { item.nombre_locacion } num_sospechoso = { item.y } key = {item.id}  />
                          </div>
                        ))
                      : null
                    : null
                  }
                </div>
              </div>
              <div className ="col-md-12">
                <div className="row">
                  {
                    gpuestos ?
                      <div className ="col-md-6" style={{ marginTop: 10 }}>
                        <GraficaOtros titulo = 'Personas en puestos' datos = {gpuestos} />
                      </div>
                    :null
                  }
                  {
                    gencuestas ?
                      <div className ="col-md-6" style={{ marginTop: 10 }}>
                        <GraficaOtros titulo = 'Asistencia' datos = {gencuestas} />
                      </div>
                    :null
                  }
                  {
                    gedades ?
                      <div className ="col-md-6" style={{ marginTop: 10 }}>
                        <GraficaOtros titulo = 'Edades' datos = {gedades} />
                      </div>
                    :null
                  }
                  {
                    gpersonal ?
                      <div className ="col-md-6" style={{ marginTop: 10 }}>
                        <GraficaOtros titulo = 'Personal' datos = {gpersonal} />
                      </div>
                    :null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center" style={{ marginTop: 20 }}>
          <Pdf targetRef={ref} options={options} x={1.2} y={.5} scale={0.7} filename="informe-general">
            {({ toPdf }) => <button type="button" className="btn btn-primary btn-sm" onClick={toPdf}>Generar PDF</button>}
          </Pdf>
        </div>
      </div>
    </div>
  )
}

export default Dashboard