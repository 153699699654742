import React, { useEffect, useState, useContext } from 'react'
import { getData, updateItem } from '../helpers'
import Formulario from './Formulario'
import Tabla from './Tabla'
import { AppContext } from '../contextApp'

const Personal = () =>{  
  const [personals, setPersonals]= useState([]);
  const [user, setUser]= useState({});
  const [asistencias, setAsistencias]= useState([]);
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getPersonal = async()=>{
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    setUser(usr);
    let ruta = 'personal'
    if(usr.cliente_id !== 0){
      ruta = 'personal/'+usr.cliente_id
    }
    const send = await getData(ruta);
    console.log(send)
    let list_personal = [{
      value: 0,
      label: 'Todos'
    }];
    if(send.data){
      send.data.map(item=>{
        list_personal.push({
          value: item.id,
          label: item.nombre_completo,
        })
      setPersonals(list_personal)
      return;
      })
    }
  }


  useEffect(()=>{
    getPersonal();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <Formulario 
          personals = {personals}
          setAsistencias = {setAsistencias}
          setMostrarMensaje2 = {setMostrarMensaje2}
          user = {user}
        />
        <Tabla 
          mostrarmensaje2 = {mostrarmensaje2}
          asistencias = {asistencias}
        />
      </div>
    </div>
  )
}

export default Personal