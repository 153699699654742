import React, { useEffect, useState, useContext } from 'react'
import { deleteItem, getData, insertDataJson } from '../../../helpers'
import FiltroLocaciones from '../../../../components/Componentes/FiltroLocaciones'
import Formulario from './Formulario'
import Preguntas from './Preguntas'
import { AppContext } from '../../../contextApp'

const Bitacora = () =>{  
  const { dominio } = useContext(AppContext);
  const [preguntas, setPreguntas]= useState([]);
  const [clientes, setClientes] = useState([]);
  const [locaciones, setLocaciones] = useState([]);
  const [usuario, setUsuario] = useState({});
  const [filtro, setFiltro] = useState({
    locacion_id: 0
  });


  const getPreguntas = async()=>{
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    setUsuario(usr);
    let ruta = 'preguntas'
    if(filtro.locacion_id > 0){
      const send = await getData(ruta+'/'+filtro.locacion_id);
      console.log(send);
      if(send.data){
        setPreguntas(send.data)
        return;
      }
    }
  }

  const getClientes = async()=>{
    const send = await getData('cliente');
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    if(parseInt(usr.cliente_id) > 0){
      const cliente = send.data.filter(item=> parseInt(item.id) === parseInt(usr.cliente_id));
      setClientes(cliente);
      return; 
    }
    if(send.data){
      setClientes(send.data)
      return;
    }
  }

  const getLocaciones = async(id)=>{
    const send = await getData('locacion/'+id);
    console.log(send);
    if(send.data){
      setLocaciones(send.data)
      return;
    }
  }

  const eliminarConsigna = async ( id ) =>{
    const send = await deleteItem('preguntas/'+id);
    console.log(send);
    if(send.code === 200){
      getPreguntas()
      return;
    }
  }
  
  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    await getClientes();
    await getLocaciones(data.cliente_id);
    setFiltro(data);
  }


  useEffect(()=>{
    getPreguntas();
    getClientes();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <FiltroLocaciones 
          getDatos = {getPreguntas}
          setFiltro = {setFiltro}
          filtro = {filtro}
          getLocaciones = {getLocaciones} 
          clientes = {clientes}
          locaciones = {locaciones}
        />
        {
        parseInt(usuario.cliente_id) > 0 && usuario.admin ===  'false' ?
          null
        :
         preguntas.length > 0 ?
          <Formulario  areas = { preguntas } getPreguntas = { getPreguntas } />
         : null 
        }
        <Preguntas 
          usuario = {usuario}
          datos = {preguntas}
          getPreguntas ={getPreguntas}
          eliminarConsigna = {eliminarConsigna}
        />
      </div>
    </div>
  )
}

export default Bitacora