import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const GraficaGeneral =( { datos } )=>{
  const num_incidentes = datos.total_incidentes.reduce((sum, value) => ( sum + parseInt(value.total_incidentes) ), 0);
  let horas_inversion = datos.horas_inversion.reduce((sum, value) => ( sum + parseInt(value.horas_rondas_inversion) ), 0);
  horas_inversion = (parseFloat(horas_inversion/60)).toFixed(2)
  const total_rondas = datos.total_rondas.reduce((sum, value) => ( sum + parseInt(value.total_rondas) ), 0);
  const options_general = {  
    chart: {
      type: 'bar',
      height: 300
    },
    credits: {
      enabled: false
    },
    title: {
        text: ''
    },
    xAxis: {
        categories: ['Cantidad de Incidentes', 'Horas de inversion de rondas', 'Cantidad de Rondas'],
        title: {
            text: null
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: '',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        }
    },
    tooltip: {
        valueSuffix: ' millions'
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true
            }
        }
    },
    credits: {
        enabled: false
    },
    series: [{
        name: 'Total',
        data: [num_incidentes, parseFloat(horas_inversion), total_rondas],
        color: '#96d74b'
    }]
  }
  

  return(

    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={options_general}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraficaGeneral