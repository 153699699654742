import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {insertData} from '../helpers'


const GraficaGeneral =( { datos } )=>{
  
  const options_general = {  
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 300
    },
    credits: {
      enabled: false
    },
    exporting:{
      enabled: false
    },
    title: {
        text: '<b style="font-size: 30px">'+ ( parseInt(datos[0].y) + parseInt(datos[1].y))+'</b>',
        align: 'center',
        verticalAlign: 'middle',
        y: -1
    },
    subtitle:{
      text: '<p>Total</p><br/><p>encuestados</p>',
      align: 'center',
      verticalAlign: 'middle',
      y: 10
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
          showInLegend: true,
          dataLabels: {
            enabled: true,
            format: '{point.name}<br><b>({point.y})</b>',
            distance: 10,
            filter: {
                property: 'y',
                operator: '>',
                value: 1
                }
            },
            size: '90%'
        }
    },
    series: [{
        name: 'Total',
        innerSize: '60%',
        data: [
            {
              y: datos[0] ? datos[0].y : 0,
              name: "Sin sospecha",
              color: "#8ddc39"
            },
            {
              y: datos[0] ? datos[1].y : 0,
              name: "Sospechoso",
              color: "#ff9800"
            },
        ]
    }]
  }
  

  return(

    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={options_general}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraficaGeneral