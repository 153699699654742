import React from 'react'

const Tabla = ({ guardia, verDetalle }) =>{
  return(
    <div className="col-md-3">
      <div className="card mb-3">
        <div className="card-body" style={{ display: 'flex', flexDirection: 'column' }}>
            <div className ="foto-perfil">

            </div>
            <div>
              <a href="#" onClick={()=>verDetalle(guardia)} style={{ fontSize: 14, fontWeight: 'bold' }}>Nombre: { guardia.name }</a>
              <p style={{ fontSize: 12 }}>Usuario: { guardia.email }</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Tabla