import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const GraficaLocacionBarras =( { datos, nombre } )=>{
  
  let lista_filtrada_consginas = [];
  let lista_filtrada_consginas_si = [];
  let porcentaje_consigna = 0;
  let total_consignas = 0;
  let total_consignas_si = 0;

  let lista_filtrada_cumplimiento = [];
  let lista_filtrada_cumplimiento_si = [];
  let porcentaje_cumplimiento = 0;
  let total_cumplimiento = 0;
  let total_cumplimiento_si = 0;

  if(datos.cumplimiento_consigas){
    lista_filtrada_consginas = datos.cumplimiento_consigas.filter(item => item.nombre_locacion === nombre);
    total_consignas = lista_filtrada_consginas.reduce((sum, value) => ( sum + parseInt(value.total) ), 0);
    lista_filtrada_consginas_si = datos.cumplimiento_consigas.filter(item => item.nombre_locacion === nombre && item.respuesta === 'si');
    total_consignas_si = lista_filtrada_consginas_si.reduce((sum, value) => ( sum + parseInt(value.total) ), 0);
    
    if(parseInt(total_consignas) > 0){
      porcentaje_consigna = (parseInt(total_consignas_si)/ parseInt(total_consignas)*100).toFixed(2);
    }
    
  }

  if(datos.cumplimientos_rondas){
    lista_filtrada_cumplimiento = datos.cumplimientos_rondas.filter(item => item.nombre_locacion === nombre);
    if(lista_filtrada_cumplimiento.length > 0){
      total_cumplimiento = lista_filtrada_cumplimiento.reduce((sum, value) => ( sum + parseInt(value.cumplimiento) ), 0);
      lista_filtrada_cumplimiento_si = datos.cumplimientos_rondas.filter(item => item.nombre_locacion === nombre && parseInt(item.status) === 0);
      total_cumplimiento_si = lista_filtrada_cumplimiento_si.reduce((sum, value) => ( sum + parseInt(value.cumplimiento) ), 0);
    }
    if(parseInt(total_cumplimiento) > 0){
      porcentaje_cumplimiento = (parseInt(total_cumplimiento_si)/ parseInt(total_cumplimiento)*100).toFixed(2);
    }
    console.log('total cumplimiento: '+ porcentaje_cumplimiento)
  }

  const options_general = {  
    chart: {
      type: 'bar',
      height: 75
    },
    credits: {
      enabled: false
    },
    title: {
        text: ''
    },
    xAxis: {
        categories: ['Estatus de Consignas', 'Rendimiento'],
        title: {
            text: null
        }
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: '',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        }
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true,
                format: '{y}%'
            }
        }
    },
    credits: {
        enabled: false
    },
    legend: {
      enabled: false
    },
    series: [{
        name: 'Total',
        data: [parseFloat(porcentaje_consigna), parseFloat(porcentaje_cumplimiento)],
        color: '#ffc107'
    }]
  }
  

  return(

    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={options_general}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraficaLocacionBarras