import React, { useEffect, useState, useContext } from 'react'
import { getData, insertData, insertDataJson } from '../../../helpers'
import { useLocation } from 'react-router-dom'

import TablaDetalle from './TablaDetalle'
import ReporteObservaciones from './ReporteObservaciones'
import Consignas from './Consignas'
import DatosUsuario from './DatosUsuario'
import DatosLocacion from './DatosLocacion'

const Bitacora = () =>{  
  const [bitacora, setBitacora]= useState([]);
  const [locacion, setLocacion] = useState({})
  const [reporteincidente, setReporteIncidente] = useState([]);
  const [filtro, setFiltro] = useState({
    locacion_id: 0
  });

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const getBitacora = async()=>{
    let fecha = query.get("fecha");
    let locacion_id = query.get("locacion_id");
    let ruta = 'checks-rondas-detalle'
    let datos = {
      fecha: fecha,
      locacion_id: locacion_id
    }
    const send = await insertDataJson(ruta,datos);
    console.log(send);
    if(send.data){
      setBitacora(send.data)
      return;
    }
  }

  const getReporteIncidente = async()=>{
    let fecha = query.get("fecha");
    let locacion_id = query.get("locacion_id");
    let ruta = 'observacion-pregunta';
    let datos = {
      fecha: fecha,
      locacion_id: locacion_id
    }
    const send = await insertDataJson(ruta,datos);
    if(send.data){
      setReporteIncidente(send.data)
      return;
    }
  }

  const getLocacion = async()=>{
    let locacion_id = query.get("locacion_id");
    let datos = {
      value: locacion_id
    }
    const send = await insertDataJson('locacion-usuario',datos);
    console.log(send);
    if(send.data){
      setLocacion(send.data)
      return;
    }
  }


  useEffect(()=>{
    getBitacora();
    getReporteIncidente();
    getLocacion();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        {/* <DatosUsuario /> */}
        <DatosLocacion 
          locacion = { locacion }
        />
        <TablaDetalle 
          bitacora = { bitacora }
        />
        <Consignas 
          datos = { bitacora }
        />
        <ReporteObservaciones
          reporteincidente = {reporteincidente}
        />
      </div>
    </div>
  )
}

export default Bitacora