import React, { useEffect, useState } from 'react'

const Formulario = ({getDatos, setFiltro, filtro, clientes, locaciones, getLocaciones}) =>{
  const { locacion_id } = filtro;
    const [nivel, setNivel] = useState(false);
    const [usuario, setUsuario] = useState({});
    const [clientesfiltrado, setClientesFlitrado] = useState([]);

  const actualizaState = e =>{
    setFiltro({
      ...filtro,
      [e.target.name] : e.target.value
    })

    if(e.target.name === 'cliente_id'){
      getLocaciones(e.target.value)
    }
  } 

  const submitFormulario = async e =>{
    e.preventDefault();
    getDatos();
  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            FILTROS
          </div>
        </div>
        <div className="card-body">
          <form className=""
            onSubmit={submitFormulario}
          > 
              <label>Cliente:</label>
              <select 
                className="form-control"
                name="cliente_id" 
                onChange = {actualizaState}

                >
                <option value = "0">-- Seleccione un cliente --</option>
                {
                    clientes ?
                    clientes.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.nombre_comercial}
                      </option>
                    ))
                  :null
                }
              </select>
              <label>Locacion:</label>
              <select 
                className="form-control"
                name="locacion_id" 
                value = {locacion_id}
                onChange = {actualizaState}

                >
                <option value = "0">-- Seleccione una locacion --</option>
                {
                    locaciones ?
                    locaciones.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.nombre_locacion}
                      </option>
                    ))
                  :null
                }
              </select>
              <div style={{ marginTop: 10 }}></div>
              <button className="mt-1 btn btn-success">Consultar</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formulario