import React, { useState } from 'react'
import Mensaje from '../../../Mensaje'
import { insertDataJson } from '../../../helpers'

const Formulario = ({ areas, getRondas }) =>{
  const [ronda, setRonda] = useState({
    qr_bitacora_id: 0,
    dias: '',
    hora: '00:00:00',
    descripcion: '',
    cadena: '-',
    minutos_tolerancia: 10,
    recurrente: 1
  })
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const actualizaState = e =>{
    setRonda({
      ...ronda,
      [e.target.name] : e.target.value
    })
  } 



  const submitFormulario = async e =>{
    e.preventDefault();
    if(ronda.qr_bitacora_id === 0){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Debe seleccionar un área',
        etiqueta: 'error'
      })
      return;
    }
    if(ronda.hora === ''){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Todos los campos (*) son requeridos',
        etiqueta: 'error'
      })
      return;
    }
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Guardando....',
      etiqueta: 'primary'
    });
    let ruta = 'ronda-programada';
    const send = await insertDataJson(ruta,ronda);
    console.log(send)
    if(send.code === 201){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Se ha guardado correctamente',
        etiqueta: 'success'
      });
      getRondas();
      return;
    }
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Ha ocurrido algo y no se guardó la ronda',
      etiqueta: 'error'
    })
    console.log(ronda);
  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-body">
          <form className=""
            onSubmit={submitFormulario}
          > 
              <label>Área:</label>
              <select 
                className="form-control"
                name="qr_bitacora_id" 
                value = {ronda.qr_bitacora_id}
                onChange = {actualizaState}

                >
                <option value = "0">-- Seleccione un área --</option>
                {
                    areas ?
                    areas.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.area}
                      </option>
                    ))
                  :null
                }
              </select>
                <div className="row">
                  {/* <div className="col-md-2">
                    <label>Hora(*)</label>
                    <input  
                      type="time" 
                      className="mb-2 form-control-sm form-control" 
                      name = "hora"
                      value = {ronda.hora}
                      onChange = {actualizaState}
                      required
                      />
                  </div> */}
                  <div className="col-md-2">
                    <label>Tiempo de recorrido en minutos:</label>
                    <input  
                      type="number" 
                      className="mb-2 form-control-sm form-control col-md-6" 
                      name = "minutos_tolerancia"
                      value = {ronda.minutos_tolerancia}
                      onChange = {actualizaState}
                      required
                      />                    
                  </div>
                </div>
              <label>Descripción(*):</label>
              {/* <input  
                type="text" 
                className="mb-2 form-control-sm form-control" 
                name = "descripcion"
                value = {ronda.descripcion}
                onChange = {actualizaState}
                /> */}
                { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
              <div style={{ marginTop: 10 }}></div>
              <button className="mt-1 btn btn-primary">Guardar</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formulario