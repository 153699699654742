import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const GraficaLocacion =( { datos, nombre } )=>{
  let lista_filtrada = [];
  let total = 0;
  let lista_filtrada_si = [];
  let total_cumplimiento = 0;
  if(datos.cumplimiento_equipo_locaciones){
    lista_filtrada = datos.cumplimiento_equipo_locaciones.filter(item => item.nombre_locacion === nombre);
    total = lista_filtrada.reduce((sum, value) => ( sum + parseInt(value.total) ), 0);
  }
  if(datos.cumplimiento_equipo_locaciones){
    lista_filtrada_si = datos.cumplimiento_equipo_locaciones.filter(item => item.nombre_locacion === nombre && item.respuesta === 'si');
    total_cumplimiento = lista_filtrada_si.reduce((sum, value) => ( sum + parseInt(value.total) ), 0);
  }

  const options_general = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: 225
    },
    credits: {
      enabled: false
    },
    exporting:{
      enabled: false
    },
    title: {
        text: '<b style="font-size: 30px">'+ total +'</b>',
        align: 'center',
        verticalAlign: 'middle',
        y: 50
    },
    subtitle:{
      text: nombre,
      align: 'center',
      verticalAlign: 'middle',
      y: 65
    },
    
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>({point.y})',
            distance: 0,
            filter: {
                property: 'y',
                operator: '>',
                value: 1
                }
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            size: '150%'
        }
    },
    series: [{
        name: 'Total',
        type: 'pie',
        innerSize: '60%',
        data: [
            {
              y: total_cumplimiento,
              name: "Cumplimiento",
              color: "#00bcd4"
            },
            {
              y: total - total_cumplimiento,
              name: "Incumplimiento",
              color: "#ff9800"
            }
        ]
    }]
  }
  

  return(

    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={options_general}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraficaLocacion