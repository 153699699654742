import React, { Fragment, useState } from 'react'
import Tabla from './Tabla'
import { updateItem } from '../../../helpers'

const Preguntas = ({ datos, eliminarConsigna, getPreguntas, usuario}) =>{
  const [nuevonombre, setNuevoNombre] = useState('');
  const [nuevoorden, setNuevoOrden] = useState('');
  const [nuevotiempo, setNuevoTiempo] = useState('');
  const [mostrar, setMostrar] = useState(0);
  const actualizarStatus = async ( status, id) =>{
    let datos = {
      status: 0
    }
    if(parseInt(status) === 0){
      datos = {
        status: 1
      }
    }

    let ruta = 'qr-locacion';
    const send = await updateItem(ruta+'/'+id,datos);
    console.log(send)
    if(send.code === 200){
      getPreguntas();
      return;
    }
  }

  const actualizarNombre = async ( area, id) =>{
    if(nuevonombre === ''){
      return;
    }
    let datos = {
      area: nuevonombre
    }
    let ruta = 'qr-locacion';
    const send = await updateItem(ruta+'/'+id,datos);
    console.log(send)
    if(send.code === 200){
      getPreguntas();
      setMostrar(0);
      return;
    }
  }

  const actualizarOrden = async (id) =>{
    if(nuevoorden === ''){
      return;
    }
    let datos = {
      orden: nuevoorden
    }
    let ruta = 'qr-locacion';
    const send = await updateItem(ruta+'/'+id,datos);
    console.log(send)
    if(send.code === 200){
      getPreguntas();
      setMostrar(0);
      return;
    }
  }

  const actualizarTiempo = async (id) =>{
    if(nuevotiempo === ''){
      return;
    }
    let datos = {
      tiempo_estancia: nuevotiempo
    }
    let ruta = 'qr-locacion';
    const send = await updateItem(ruta+'/'+id,datos);
    console.log(send)
    if(send.code === 200){
      getPreguntas();
      setMostrar(0);
      return;
    }
  }

  const actualizaState = e =>{
    setNuevoNombre(e.target.value);
  } 

  const actualizaStateOrden = e =>{
    setNuevoOrden(e.target.value);
  } 

  const actualizaStateTiempo = e =>{
    setNuevoTiempo(e.target.value);
  } 

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            CONSIGNAS
          </div>
        </div>
        <div className="card-body">
          {
            datos.map(item=>(
              <div>
                {
                  mostrar === item.id ?
                  <Fragment>
                    <input  
                      type="text" 
                      placeholder= "Nuevo nombre"
                      style={{ width: 150, float: 'left', borderColor: nuevonombre === '' ? 'red' : null }}
                      className="mb-2 form-control-sm form-control" 
                      name = {item.id}
                      onChange = {actualizaState}
                    />
                    <button type="button" onClick = {()=>actualizarNombre(item.area, item.id)} className="btn btn-success btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#">
                      <li className="fa fa-check-square"></li>
                    </button>             
                    <br/>       
                    <input  
                      type="text" 
                      placeholder= "Número orden"
                      style={{ width: 150, float: 'left', borderColor: nuevonombre === '' ? 'red' : null }}
                      className="mb-2 form-control-sm form-control" 
                      name = {item.id}
                      onChange = {actualizaStateOrden}
                    />
                    <button type="button" onClick = {()=>actualizarOrden(item.id)} className="btn btn-success btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#">
                      <li className="fa fa-check-square"></li>
                    </button>
                    <br/>       
                    <input  
                      type="text" 
                      placeholder= "Tiempo estancia"
                      style={{ width: 150, float: 'left', borderColor: nuevonombre === '' ? 'red' : null }}
                      className="mb-2 form-control-sm form-control" 
                      name = {item.id}
                      onChange = {actualizaStateTiempo}
                    />
                    <button type="button" onClick = {()=>actualizarTiempo(item.id)} className="btn btn-success btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#">
                      <li className="fa fa-check-square"></li>
                    </button>
                  </Fragment>
                  : null
                }
                <h5>{ item.area } 
                {
                  parseInt(usuario.cliente_id) > 0 && usuario.admin ===  'false' ?
                  null
                  :
                  <Fragment>
                    <button type="button" onClick = {()=>setMostrar(item.id)} className="btn btn-default btn-sm" style={{ marginLeft: 10 }} data-tip="Cancelar" data-toggle="modal" data-target="#">
                      <li className="fa fa-edit"></li>
                    </button>
                    {
                      parseInt(item.status) === 0 ?
                      <button type="button" onClick = {()=>actualizarStatus(item.status, item.id)} className="btn btn-default btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#">
                        <li className="fa fa-eye"></li>
                      </button>
                      :
                      <button type="button" onClick = {()=>actualizarStatus(item.status, item.id)} className="btn btn-default btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#">
                        <li style={{ color: 'red' }} className="fa fa-eye-slash"></li>
                      </button>
                    }
                  </Fragment>
                }
                <label> Orden: { item.orden === '' ? '' : item.orden } </label>
                <label> | Tiempo total estancia: { item.tiempo_estancia === '' ? 0 : item.tiempo_estancia } </label>
                </h5>
                  <Tabla 
                    usuario = { usuario }
                    preguntas = { item.preguntas }
                    eliminarConsigna = { eliminarConsigna }
                  />
                <hr/>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Preguntas