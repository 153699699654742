import React, { useEffect, useState, useContext } from 'react'
import { deleteItem, getData, insertData, insertDataJson, updateItem } from '../../../helpers'
import FiltroLocaciones from '../../../Componentes/FiltroLocaciones'
import Formulario from './Formulario'
import Tabla from './Rondas'
import { AppContext } from '../../../contextApp'

const Bitacora = () =>{  
  const { dominio } = useContext(AppContext);
  const [preguntas, setPreguntas]= useState([]);
  const [clientes, setClientes] = useState([]);
  const [locaciones, setLocaciones] = useState([]);
  const [filtro, setFiltro] = useState({
    locacion_id: 0
  });


  const getRondas = async()=>{
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    let ruta = 'ronda-programada'
    if(filtro.locacion_id > 0){
      const send = await getData(ruta+'/'+filtro.locacion_id);
      console.log(send);
      if(send.data){
        setPreguntas(send.data)
        return;
      }
    }
  }

  const getClientes = async()=>{
    const send = await getData('cliente');
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    if(parseInt(usr.cliente_id) > 0){
      const cliente = send.data.filter(item=> parseInt(item.id) === parseInt(usr.cliente_id));
      setClientes(cliente);
      return; 
    }
    if(send.data){
      setClientes(send.data)
      return;
    }
  }

  const getLocaciones = async(id)=>{
    const send = await getData('locacion/'+id);
    console.log(send);
    if(send.data){
      setLocaciones(send.data)
      return;
    }
  }

  const eliminaRonda = async ( id, status ) =>{
    let datos = {
      status: status
    }
    const send = await updateItem('ronda-programada/'+id, datos);
    console.log(send)
    if(send.code === 200){
      getRondas();
    }

  }
  
  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    await getClientes();
    await getLocaciones(data.cliente_id);
    setFiltro(data);
  }

  const verDetalle = (data) =>{
    window.location = `/guardias/detalle-bitacora?fecha=${data.fecha}&locacion_id=${data.locacion_id}`;
  }


  useEffect(()=>{
    getRondas();
    getClientes();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <FiltroLocaciones 
          getDatos = {getRondas}
          setFiltro = {setFiltro}
          filtro = {filtro}
          getLocaciones = {getLocaciones} 
          clientes = {clientes}
          locaciones = {locaciones}
        />
        {
         preguntas.length > 0 ?
          <Formulario  areas = { preguntas } getRondas = { getRondas } />
         : null 
        }
        <Tabla 
          datos = {preguntas}
          verDetalle = {verDetalle}
          eliminaRonda = {eliminaRonda}
          getRondas = {getRondas}
        />
      </div>
    </div>
  )
}

export default Bitacora