import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component'

const Tabla = ({ consignas }) =>{

  const columnas = [
    {
      name: 'Pregunta',
      selector : 'pregunta',
      width: 100
    }, 
    {
      name: 'Respuesta',
      selector : 'respuesta',
      width: 100
    },
    {
      name: 'Observaciones',
      cell: (row) => 
      <div style={{ marginBottom: 20, marginTop: 150 }}>
        {
          row.observaciones.map(item=>(
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div>
                {item.observaciones}
              </div>
              <div>
                <img width="180" src={item.foto1} />
                <img width="180" src={item.foto2} />
              </div>
              Prioridad: <b>{item.prioridad}</b>
              <hr/>
            </div>
          ))
        }
      </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: true,
      width:'50%'
    }
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-body">
          {
          consignas.map(item=>(
            <Fragment>
              <p style={{ marginBottom: 0 }}>Pregunta: <b>{ item.pregunta }</b></p>
              <p>Respuesta: { item.respuesta }</p>
              {
                item.observaciones.map(item2=>(
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <div style={{ float: 'left', width: '50%', padding: 20 }}>
                    {item2.observaciones}
                  </div>
                  <div>
                    <img width="180" src={item2.foto1} />
                    <img width="180" src={item2.foto2} />
                  </div>
                  Prioridad: <b>{item2.prioridad}</b>
                  <hr/>
                </div>
                ))
              }
            </Fragment>
            ))
          }
          {/* <DataTable
              columns= {columnas}
              noHeader
              data={consignas}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            /> */}
        </div>
      </div>
    </div>
  )
}

export default Tabla