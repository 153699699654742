import React, { useState } from 'react'
import DataTable from 'react-data-table-component'
import { CSVLink } from "react-csv";
import { getFecha } from '../../../helpers'

const Tabla = ({ bitacora, getTiemposAreas }) =>{
const [cont, setCont] = useState(0);

  const contador = (x2) =>{
    setCont(x2)  
  }

  const columnas = [
    {
      name: 'Locacion',
      selector : 'nombre_locacion',
    },
    {
      name: 'Nombre',
      selector : 'nombre',
    },
    {
      name: 'Rondas',
      cell: (row) => <div>
                        Ronda {
                          contador()
                        }
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: true,
      width: 50
    },
    {
      name: 'Áreas recorridas',
      selector : 'num_areas',
      sortable: true,
      width: 50
    },
    {
      name: 'Fecha',
      selector : 'fecha',
      sortable: true,
      width: 50
    },
    {
      name: 'Hora recorrido',
      selector : 'tiempo_total',
      sortable: true,
      width: 50
    },
    // {
    //   name: 'Fecha',
    //   selector : 'fecha',
    //   sortable: true,
    //   width: 50
    // },
    // {
    //   name: 'Hora',
    //   selector : 'hora',
    //   width: 50
    // },
    {
      name: 'Detalle',
      cell: (row) => <div>
                        {
                         row.clave ?
                          <button onClick = {()=>getTiemposAreas(row.clave)} type="button" className="btn btn-outline-primary btn-sm" data-tip="Detalle">
                            <li className="fa fa-eye"></li> 
                          </button>
                         : null 
                        }
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title row">
            <div className="col-md-6">
              RONDAS
            </div>
            <div className="col-md-6 text-right">
              {
                bitacora.length > 0 ?
                  <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
                    <li className="fa fa-cloud-download"></li>  
                    <CSVLink data={bitacora} filename={"DetalleBitacora.csv"} style={{color: 'white'}}>
                      Descargar Excel</CSVLink>
                  </button> 
                :
                null
              }
              </div>
          </div>
        </div>
        <div className="card-body">
          <DataTable
              columns= {columnas}
              noHeader
              data={bitacora}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla