import React from 'react'
import DataTable from 'react-data-table-component'

const Tabla = ({ rondas, eliminaRonda }) =>{

  const columnas = [
    // {
    //   name: 'Descripción',
    //   selector : 'descripcion',
    // },
    // {
    //   name: 'Hora',
    //   selector : 'hora',
    // },
    {
      name: 'Tiempo programado (min)',
      cell: (row) =><div>{ row.minutos_tolerancia }min</div>
    },
    {
      cell: (row) => <div>
            {
              parseInt(row.status) === 1 ?
              <button onClick = {()=>eliminaRonda(row.id, 0)}  type="button" className="btn btn-danger btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#">
                <li className="fa fa-ban"></li>
              </button>
              :
              <button onClick = {()=>eliminaRonda(row.id, 1)}  type="button" className="btn btn-success btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#">
                <li className="fa fa-check-circle"></li>
              </button>
            }
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }, 
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-body">
          <DataTable
              columns= {columnas}
              noHeader
              data={rondas}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla