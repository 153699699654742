import React, { useState } from 'react'
import Mensaje from '../../../Mensaje'
import { insertDataJson } from '../../../helpers'

const Formulario = ({ areas, getPreguntas }) =>{
  const [pregunta, setPregunta] = useState({
    qr_bitacora_id: 0,
    pregunta: ''
  })
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const actualizaState = e =>{
    setPregunta({
      ...pregunta,
      [e.target.name] : e.target.value
    })
  } 



  const submitFormulario = async e =>{
    e.preventDefault();
    if(pregunta.qr_bitacora_id === 0){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Debe seleccionar un área',
        etiqueta: 'error'
      })
      return;
    }
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Guardando....',
      etiqueta: 'primary'
    });
    let ruta = 'preguntas';
    const send = await insertDataJson(ruta,pregunta);
    console.log(send)
    if(send.code === 201){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Se ha guardado correctamente',
        etiqueta: 'success'
      });
      getPreguntas();
      return;
    }
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Ha ocurrido algo y no se guardó la pregunta',
      etiqueta: 'error'
    })
    console.log(pregunta);
  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-body">
          <form className=""
            onSubmit={submitFormulario}
          > 
              <label>Área:</label>
              <select 
                className="form-control"
                name="qr_bitacora_id" 
                value = {pregunta.qr_bitacora_id}
                onChange = {actualizaState}

                >
                <option value = "0">-- Seleccione un área --</option>
                {
                    areas ?
                    areas.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.area}
                      </option>
                    ))
                  :null
                }
              </select>
              <label>Pregunta(*)</label>
              <input  
                type="text" 
                className="mb-2 form-control-sm form-control" 
                name = "pregunta"
                value = {pregunta.pregunta}
                onChange = {actualizaState}
                required
                />
                { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
              <div style={{ marginTop: 10 }}></div>
              <button className="mt-1 btn btn-primary">Guardar</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formulario