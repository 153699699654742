import React from 'react'

const Mensaje = ({etiqueta, mensaje}) =>{
  return(
    <div>
    {
      etiqueta === 'error' ?
        <div className="alert alert-danger fade show" role="alert">
        <p style={{ color: 'white', marginBottom: 0 }}>{mensaje}</p>
        </div>
      : 
      etiqueta === 'success' ?
      <div className="alert alert-success fade show" role="alert">
        <p style={{ color: 'white', marginBottom: 0 }}>{mensaje}</p>
      </div>
      : 
      <div className="alert fade show" role="alert" style={{ backgroundColor: '#040d2f' }}>
        <p style={{ color: 'white', marginBottom: 0 }}>{mensaje}</p>
      </div>
      }
    </div>
  )
}

export default Mensaje