import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const GraficaBarraGeneralMes =( { datos } )=>{
    let rendimiento = [];
    var rend = 0;
    for (let x = 1; x<= 12; x++){
      if(datos[x]){
        if(datos[x].cumplimiento_equipo_general){
          rend = (parseFloat(datos[x].cumplimiento_equipo_general.total) / parseFloat(datos[x].cumplimiento_equipo_general.cumplimiento)) * 100;
          rend = parseFloat(rend.toFixed(2))
          rendimiento.push(rend);
        }
      }
    }

    let lista_filtrada_consginas_si = [];
    let porcentaje_consigna = 0;
    let total_consignas = 0;
    let total_consignas_si = 0;
    let consgina = [];
    
    for (let x = 1; x<= 12; x++){
      if(datos[x]){
        if(datos[x].cumplimiento_consigas){
          total_consignas = datos[x].cumplimiento_consigas.reduce((sum, value) => ( sum + parseInt(value.total) ), 0);
          lista_filtrada_consginas_si = datos[x].cumplimiento_consigas.filter(item =>  item.respuesta === 'si');
          total_consignas_si = lista_filtrada_consginas_si.reduce((sum, value) => ( sum + parseInt(value.total) ), 0);
          
          if(parseInt(total_consignas) > 0){
            porcentaje_consigna = (parseInt(total_consignas_si)/ parseInt(total_consignas)*100).toFixed(2);
          }else{
            porcentaje_consigna = 0;
          }
          consgina.push(parseFloat(porcentaje_consigna));
        }
      }
    }
    console.log(consgina)

    const options_general = {  
    chart: {
      type: 'column'
  },
  title: {
      text: 'Comportamiento Anual'
  },
  xAxis: {
      categories: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
  },
  yAxis: {
      min: 0,
      title: {
          text: ''
      }
  },
  legend: {
      reversed: true
  },
  credits: {
    enabled: false
  },
  tooltip: {
      pointFormat: '{series.name}: <b>{point.y}%</b>'
  },
  accessibility: {
      point: {
          valueSuffix: '%'
      }
  },
  plotOptions: {
      series: {
          stacking: 'normal',
          dataLabels: {
              enabled: true,
              format: '{y}%',
              borderWidth: 0,
              borderColor: 'red'
          }
      }
  },
  series: [{
      name: 'Evaluacion general de rendimiento',
      data: rendimiento,
      color: '#03a9f4'
  }, {
      name: 'Cumplimiento General de cosignas',
      data: consgina,
      color: '#00bcd4'
  }]
  }
  

  return(

    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={options_general}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraficaBarraGeneralMes