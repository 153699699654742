import React, { Fragment, useState, useEffect} from 'react';


const Home=props=>{
  const [usuario, setUsuario] = useState({});

  const getUsuario = async () =>{
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    if(!usr){
      window.location = '/login'
    }
    setUsuario(usr);
  }
  
  useEffect(() => { 
    getUsuario();
  }, [])
  return(
  <Fragment>
    <section className="h-100-vh mb-8">
      <div className="page-header align-items-start section-height-50 pt-5 pb-11 m-3 border-radius-lg" style= {{ backgroundImage: 'url(../../../assets/img/curved-images/curved6.jpg)' }}>
        <span className="mask bg-gradient-dark opacity-6"></span>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 text-center mx-auto">
              <img style={{ width: 80, height: 80 }} src="logo_sippsa_blanco.png" alt="..." />
              <h1 className="text-white mb-0 mt-0">PROCESOS</h1>
              <p className="text-lead text-white" >  QHSE CONTROL.</p>
              <h5 className="text-white mb-2 mt-5">Bienvenido, {usuario.name}!</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-lg-n10 mt-md-n11 mt-n10">

          <div className="col-md-3 mx-auto">
            <div className="card z-index-0">
              <div className="card-header text-center pt-4">
                <h4>QHSE</h4>
              </div>
              <div className="row px-xl-5 px-sm-4 px-3">
                <div className="col-12 px-1 text-center">
                  <p>Protocolo COVID</p>
                  <a className="btn btn-outline-light w-100" href="/">
                    <img style={{ width: 60 }} src="/menu/covid.png" alt="..." />
                  </a>
                </div>
              </div>
              <div className="card-body">

              </div>
            </div>
          </div>

          <div className="col-md-3 mx-auto">
            <div className="card z-index-0">
              <div className="card-header text-center pt-4">
                <h4>RH</h4>
              </div>
              <div className="row px-xl-5 px-sm-4 px-3">
                <div className="col-12 px-1 text-center">
                  <p>Reclutamiento</p>
                  <a className="btn btn-outline-light w-100" href="javascript:;">
                    <img style={{ width: 60 }} src="/menu/rh.png" alt="..." />
                  </a>
                </div>
              </div>
              <div className="card-body">

              </div>
            </div>
          </div>

          <div className="col-md-3 mx-auto">
            <div className="card z-index-0">
              <div className="card-header text-center pt-4">
                <h4>ALMACÉN</h4>
              </div>
              <div className="row px-xl-5 px-sm-4 px-3">
                <div className="col-12 px-1 text-center">
                  <p>Control almacén</p>
                  <a className="btn btn-outline-light w-100" href="javascript:;">
                    <img style={{ width: 60 }} src="/menu/almacen.png" alt="..." />
                  </a>
                </div>
              </div>
              <div className="card-body">

              </div>
            </div>
          </div>

          <div className="col-md-3 mx-auto">
            <div className="card z-index-0">
              <div className="card-header text-center pt-4">
                <h4>LOGÍSTICA</h4>
              </div>
              <div className="row px-xl-5 px-sm-4 px-3">
                <div className="col-12 px-1 text-center">
                  <p>Control logística</p>
                  <a className="btn btn-outline-light w-100" href="javascript:;">
                    <img style={{ width: 60 }} src="/menu/logistica.png" alt="..." />
                  </a>
                </div>
              </div>
              <div className="card-body">

              </div>
            </div>
          </div>      
        </div>
        <div className="row mt-3">
          <div className="col-md-3 mx-auto">
            <div className="card z-index-0">
              <div className="card-header text-center pt-4">
                <h4>OPERACIONES</h4>
              </div>
              <div className="row px-xl-5 px-sm-4 px-3">
                <div className="col-12 px-1 text-center">
                  <p>Guardias de seguridad</p>
                  <a className="btn btn-outline-light w-100" href="guardias">
                    <img style={{ width: 60 }} src="/menu/operaciones.png" alt="..." />
                  </a>
                </div>
              </div>
              <div className="card-body">

              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
        <footer class="footer">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 mb-4 mx-auto text-center">
                <a href="https://sippsa.mx" target="_blank" class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2">
                  Sippsa.mx | Todos los derechos reservados.
                </a>
              </div>
            </div>
          </div>
        </footer>
        </div>


      </div>
    </section>
  </Fragment>
  )
}
export default Home

