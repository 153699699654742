import React from 'react'
import DataTable from 'react-data-table-component'

const Tabla = ({ datos, verDetalle }) =>{

  const columnas = [
    {
      name: 'Fecha',
      selector : 'fecha_check',
    },
    {
      name: 'Num. Rondas',
      selector : 'num_rondas',
    }, 
    {
      name: 'Check en tiempo',
        cell: (row) => <div>
                        <label>{row.num_check_rondas}/{parseInt(row.num_check_rondas) - parseInt(row.num_check_rondas_fuera)}</label>
                      </div>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    {
      name: 'Detalle',
      cell: (row) => <div>
                        <button onClick={()=>verDetalle(row)} type="button" className="btn btn-outline-primary btn-sm" data-tip="Detalle">
                          <li className="fa fa-eye"></li> 
                        </button>
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-body">
          <DataTable
              columns= {columnas}
              noHeader
              data={datos}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla