import React, { useState } from 'react'
import { insertDataJson, updateItem } from '../helpers'
import Mensaje from '../Mensaje'

const Formulario = ({getUsuarios, setUsuario, usuario, clientes, locaciones, puestos, getLocaciones, getPuestos, setActualizar, actualizar}) =>{
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const {     
    name,
    email,
    password,
    password_confirmation,
    admin,
    personal_id,
    cliente_id,
    qhse,
    gerente,
    direccion,
    jefe_grupo,
    status,
    locacion_id, } = usuario;
    const [nivel, setNivel] = useState(false);

  const actualizaState = e =>{
    setUsuario({
      ...usuario,
      [e.target.name] : e.target.value
    })

    if(e.target.name === 'cliente_id'){
      getLocaciones(e.target.value)
    }
  } 

  const reiniciarForm = (e) =>{
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Se ha guardado correctamente',
      etiqueta: 'success'
    })
    setUsuario({
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      admin: 'false',
      personal_id: 0,
      cliente_id: 0,
      qhse: 0,
      gerente: 0,
      direccion: 0,
      jefe_grupo: 0,
      locacion_id: 0
    })
    getUsuarios();
  }

  const setNivelUsuario = e =>{
    let nivel = e.target.value;
    setNivel(false);
    if(parseInt(nivel) === 1){
      setNivel(true);
      setUsuario({
        ...usuario,
        admin: 'true',
        jefe_grupo: 0,
        direccion: 0
      })
    }else if (parseInt(nivel) === 2){
      setNivel(true);
      setUsuario({
        ...usuario,
        admin: 'false',
        jefe_grupo: 1,
        direccion: 0
      })
    }else if (parseInt(nivel) === 3){
      setNivel(true);
      setUsuario({
        ...usuario,
        admin: 'false',
        jefe_grupo: 0,
        direccion: 0
      })
    }else if (parseInt(nivel) === 4){
      setNivel(true);
      setUsuario({
        ...usuario,
        admin: 'false',
        jefe_grupo: 0,
        direccion: 1
      })
    }
  }

  const submitFormulario = async e =>{
    e.preventDefault();

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Guardando....',
      etiqueta: 'primary'
    })


    let ruta = 'register';
    if(!actualizar){
      //Guardar
      if(name === '' || password === '' || password_confirmation === '' || nivel === false || parseInt(cliente_id) === 0){
        setMostrarMensaje({
          mostrar: true,
          mensaje: 'Todos los campos(*) son obligatorios',
          etiqueta: 'error'
        })
        return;
      }
      let datos = {
        name: usuario.name,
        email: usuario.email,
        password: usuario.password,
        password_confirmation: usuario.password_confirmation,
        personal_id: usuario.personal_id,
        cliente_id: usuario.cliente_id,
        qhse: usuario.qhse,
        gerente: usuario.gerente,
        direccion: usuario.direccion,
        jefe_grupo: usuario.jefe_grupo,
        locacion_id: usuario.locacion_id,
        admin: usuario.admin
      }
      const send = await insertDataJson(ruta,datos);
      console.log(send)
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.status==="success"){
        reiniciarForm();
      }
    }else{
      if(name === '' || parseInt(cliente_id) === 0){
        setMostrarMensaje({
          mostrar: true,
          mensaje: 'Todos los campos(*) son obligatorios',
          etiqueta: 'error'
        })
        return;
      }
      //Actualizar
      let ruta = 'usuario';
      let datos = {
        name: usuario.name,
        email: usuario.email,
        password: usuario.password,
        password_confirmation: usuario.password_confirmation,
        personal_id: usuario.personal_id,
        cliente_id: usuario.cliente_id,
        qhse: usuario.qhse,
        gerente: usuario.gerente,
        direccion: usuario.direccion,
        jefe_grupo: usuario.jefe_grupo,
        locacion_id: usuario.locacion_id,
        admin: usuario.admin,
        status: usuario.status
      }
      const send = await updateItem(ruta+'/'+usuario.id,datos);
      console.log(send);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===200){
        setActualizar(false);
        const user =  await JSON.parse(localStorage.getItem('ACEROS_USER'));
        reiniciarForm();
      }
    }

  }

  return(
    <div className="col-md-5">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            USUARIOS
          </div>
        </div>
        <div className="card-body">
          { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          <form className=""
            onSubmit={submitFormulario}
          > 
            <label>Nombre completo(*)</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "name"
              value = {name}
              onChange = {actualizaState}
              required
              />
            <label>Correo(*)</label>
            <input  
              type="email" 
              className="mb-2 form-control-sm form-control" 
              name = "email"
              value = {email}
              onChange = {actualizaState}
              required
              />
            <label>Contraseña(*)</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "password"
              value = {password}
              onChange = {actualizaState}
              />
            <label>Confirmar contraseña(*)</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "password_confirmation"
              value = {password_confirmation}
              onChange = {actualizaState}
              />
              <label>Cliente:</label>
              <select 
                className="form-control"
                name="cliente_id" 
                value = {cliente_id}
                onChange = {actualizaState}

                >
                <option value = "0">-- Seleccione un cliente --</option>
                {
                    clientes ?
                    clientes.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.nombre_comercial}
                      </option>
                    ))
                  :null
                }
              </select>
              <label>Locacion:</label>
              <select 
                className="form-control"
                name="locacion_id" 
                value = {locacion_id}
                onChange = {actualizaState}

                >
                <option value = "0">-- Seleccione una locacion --</option>
                {
                    locaciones ?
                    locaciones.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.nombre_locacion}
                      </option>
                    ))
                  :null
                }
              </select>
              <label>Nivel de usuario:</label>
              <select 
                className="form-control"
                name="nivel" 
                value = {
                  usuario.admin === 'true' && parseInt(usuario.cliente_id) !== 0 ?
                    '1'
                  :
                  parseInt(usuario.jefe_grupo) !== 0 && usuario.jefe_grupo !== null ?
                    '2'
                  :
                  parseInt(usuario.cliente_id) !== 0 && usuario.cliente_id !== null ?
                    '3'
                  :
                  parseInt(usuario.direccion) !== 0 && usuario.direccion !== null ?
                    '4'
                  :
                    '0'
                  }
                onChange = {setNivelUsuario}

                >
                <option value = "0">-- Seleccione un nivel de usuario --</option>
                <option value = "1">Administrativo</option>
                <option value = "3">Cliente</option>
                <option value = "4">Dirección</option>
                <option value = "2">Jefe de grupo</option>
              </select>
              <label>Estatus:</label>
              <select 
                className="form-control"
                name="status" 
                value = {status}
                onChange = {actualizaState}

                >
                <option value = "0">Activo</option>
                <option value = "1">Inactivo</option>
              </select>
              <div style={{ marginTop: 10 }}></div>
              {
                actualizar ?
                <button sty className="mt-1 btn btn-warning">Actualizar personal</button>
                :
                <button className="mt-1 btn btn-primary">Guardar personal</button>
              }
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formulario