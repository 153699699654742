import React from 'react'
import DataTable from 'react-data-table-component'

const Tabla = ({ preguntas, eliminarConsigna, usuario }) =>{

  const columnas = [
    {
      selector : 'pregunta',
    },
    {
      cell: (row) => <div>
                        {
                          parseInt(usuario.cliente_id) > 0 && usuario.admin ===  'false' ?
                          null
                          :
                          <button onClick = {()=>eliminarConsigna(row.id)}  type="button" className="btn btn-danger btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#">
                            <li className="fa fa-trash"></li>
                          </button>
                        }
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }, 
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-body">
          <DataTable
              columns= {columnas}
              noHeader
              data={preguntas}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla