import React, { useEffect, useState, useContext } from 'react'
import { getData, insertDataJson } from '../../../helpers'
import FiltroLocaciones from '../../../../components/Componentes/FiltroLocaciones'
import Tabla from './Tabla'
import { AppContext } from '../../../contextApp'

const Bitacora = () =>{  
  const { dominio } = useContext(AppContext);
  const [bitacora, setBitacora]= useState([]);
  const [reporteIncidete, setReporteIncidente] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [locaciones, setLocaciones] = useState([]);
  const [filtro, setFiltro] = useState({
    locacion_id: 0
  });


  const getBitacora = async()=>{
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    if(usr.admin === 'true' && parseInt(usr.cliente_id) > 0){
      if(parseInt(filtro.locacion_id) === 0){
        return;
      }
    }
    let ruta = 'rondas-locacion'
    let datos = {
      f_ini: '',
      f_fin: '',
      locacion_id: filtro.locacion_id
    }
    getReporteIncidente(datos);
    const send = await insertDataJson(ruta,datos);
    console.log(send);
    if(send.data){
      setBitacora(send.data)
      return;
    }
  }

  const getClientes = async()=>{
    const send = await getData('cliente');
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    if(parseInt(usr.cliente_id) > 0){
      const cliente = send.data.filter(item=> parseInt(item.id) === parseInt(usr.cliente_id));
      setClientes(cliente);
      return; 
    }
    console.log(send);
    if(send.data){
      setClientes(send.data)
      return;
    }
  }

  const getLocaciones = async(id)=>{
    const send = await getData('locacion/'+id);
    console.log(send);
    if(send.data){
      setLocaciones(send.data)
      return;
    }
  }
  
  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    await getClientes();
    await getLocaciones(data.cliente_id);
    setFiltro(data);
  }

  const verDetalle = (data) =>{
    window.location = `/guardias/detalle-bitacora?fecha=${data.fecha}&locacion_id=${data.locacion_id}`;
  }

  const getReporteIncidente = async( datoss )=>{
    let ruta = 'reporte-incidente-fecha'
    let datos = {
      f_ini: datoss.f_ini,
      locacion_id: datoss.locacion_id
    }
    const send = await insertDataJson(ruta,datos);
    console.log(send);
    if(send.data){
      setReporteIncidente(send.data)
      return;
    }
  }


  useEffect(()=>{
    getClientes();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <FiltroLocaciones 
          getDatos = {getBitacora}
          setFiltro = {setFiltro}
          filtro = {filtro}
          getLocaciones = {getLocaciones} 
          clientes = {clientes}
          locaciones = {locaciones}
        />
        <Tabla 
          bitacora = {bitacora}
          verDetalle = {verDetalle}
          reporteIncidete = {reporteIncidete}
        />
      </div>
    </div>
  )
}

export default Bitacora