import React from 'react'
import DataTable from 'react-data-table-component'
import Mensaje from '../Mensaje'
import Filtros from './Filtros'

const Tabla = ({personals, setPersonals, personal, setPersonal, personalfiltrado, setPersonalFiltrado, actualiza, mostrarmensaje2, clientes, getLocaciones, locaciones }) =>{

  const columnas = [
    {
      name: 'Nombre',
      cell : (row) => parseInt(row.status) === 1 ? <label style={{ color: 'red', fontWeight: 'initial' }}>{row.nombre_completo}</label> : <label style={{ fontWeight: 'initial' }}>{row.nombre_completo}</label>
    },
    {
      name: 'Puesto',
      selector : 'desc_puesto',
    },
    {
      name: 'Editar',
      cell: (row) => <div>
                        <button onClick={()=>actualiza(row)} type="button" className="btn btn-outline-warning btn-sm" data-tip="Editar">
                          <li className="fa fa-pencil"></li> 
                        </button>
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-7">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            LISTA DE PERSONAL
          </div>
        </div>
        <div className="card-body">
          <Filtros 
            setPersonals = {setPersonals}
            personals = {personals}
            setPersonal = {setPersonal}
            personal = {personal}
            clientes = {clientes}
            getLocaciones= {getLocaciones}
            locaciones = {locaciones}
            setPersonalFiltrado = {setPersonalFiltrado}
          />
          { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
          <DataTable
              columns= {columnas}
              noHeader
              data={personalfiltrado}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla