import React, { useEffect, useState } from 'react'
import { getData, insertData, insertDataJson } from '../../../helpers'
import { useLocation } from 'react-router-dom'

import TablaDetalle from './TablaDetalle'
import DatosUsuario from './DatosUsuario'

const Bitacora = () =>{  
  const [bitacora, setBitacora]= useState([]);
  const [fechas, setFechas] = useState({
    f_ini: '',
    f_fin: ''
  });
  const [validar, setValidar] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();


  const actualizaState = e =>{
    if([e.target.name] == 'f_fin'){
      if(fechas.f_ini == ''){
        setValidar(true);
      }else{
        setValidar(false);
      }
    }else{
      setValidar(false);
    }
    setFechas({
      ...fechas,
      [e.target.name] : e.target.value
    })
  }


  const getBitacora = async()=>{
    let user_id = query.get("usuario");
    let ruta = 'usuario-rondas'
    let datos = {
      user_id: user_id,
      f_ini: fechas.f_ini,
      f_fin: fechas.f_fin,
    }
    const send = await insertDataJson(ruta,datos);
    console.log(send);
    if(send.data){
      setBitacora(send.data)
      return;
    }
  }


  useEffect(()=>{
    getBitacora();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <DatosUsuario 
          usuario = {  bitacora.usuario }
        />
        <div className="col-md-3 offset-md-2">
          <label>Fecha inicio:</label>
          <input  
            style = {{ borderColor: validar ? 'red': '' }}
            type="date" 
            className="mb-2 form-control-sm form-control" 
            name = "f_ini"
            value = {fechas.f_ini}
            onChange = {actualizaState}
            required
            />
        </div>
        <div className="col-md-3">
          <label>Fecha final:</label>
          <input  
            type="date" 
            className="mb-2 form-control-sm form-control" 
            name = "f_fin"
            value = {fechas.f_fin}
            onChange = {actualizaState}
            required
            />
        </div>
        <div className="col-md-2">
          <button style={{marginTop: 25}} onClick={()=>getBitacora()} type="button" className="btn btn-success mb-1" >Consultar</button>
        </div>
        <TablaDetalle 
          bitacora = { bitacora.rondas }
        />
      </div>
    </div>
  )
}

export default Bitacora