import React, { useEffect, useState, useContext } from 'react'
import { getData, updateItem } from '../helpers'
import Formulario from './Formulario'
import Tabla from './Tabla'
import { AppContext } from '../contextApp'

const Personal = () =>{  
  const { dominio } = useContext(AppContext);
  const [usuarios, setUsuarios]= useState([]);
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [locaciones, setLocaciones] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [usuario, setUsuario] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    admin: 'false',
    personal_id: 0,
    cliente_id: 0,
    qhse: 0,
    gerente: 0,
    direccion: 0,
    jefe_grupo: 0,
    status: 0,
    locacion_id: 0
  });
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const [imagen, setImagen]= useState(null);

  const getUsuarios = async()=>{
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    let ruta = 'usuario'
    if(parseInt(usr.cliente_id) !== 0){
      ruta = 'usuario/'+usr.cliente_id
    }
    const send = await getData(ruta);
    console.log(send);
    if(send.data){
      setUsuarios(send.data)
      return;
    }
  }

  const getPuestos = async()=>{
    const send = await getData('puesto');
    console.log(send);
    if(send.data){
      setPuestos(send.data)
      return;
    }
  }

  const getClientes = async()=>{
    const send = await getData('cliente');
    console.log(send);
    if(send.data){
      setClientes(send.data)
      return;
    }
  }

  const getLocaciones = async(id)=>{
    const send = await getData('locacion/'+id);
    console.log(send);
    if(send.data){
      setLocaciones(send.data)
      return;
    }
  }
  
  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    await getClientes();
    await getLocaciones(data.cliente_id);
    setUsuario(data);
  }

  const eliminaItem = async (data) =>{
    let suspender = {
      status: parseInt(data.status) === 1 ? 0 : 1
    }
    const send = await updateItem('usuario/'+data.id,suspender);
    console.log(send);
    if(send.code===423){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: send.error,
        etiqueta: 'error'
      })
    }
    if(send.code===200){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha modificado el usuario',
        etiqueta: 'success'
      })
      setBotonEliminar(false);
      getUsuarios();
    }
  }

  useEffect(()=>{
    getUsuarios();
    getClientes();
    getPuestos();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <Formulario 
          getUsuarios = {getUsuarios}
          setUsuario = {setUsuario}
          usuario = {usuario}
          clientes = {clientes}
          locaciones = {locaciones}
          puestos = {puestos}

          getLocaciones = {getLocaciones} 
          getPuestos = {getPuestos}

          setActualizar = {setActualizar}
          actualizar = {actualizar}
        />
        <Tabla 
          mostrarmensaje2 = {mostrarmensaje2}

          usuarios = {usuarios}
          actualiza = {actualiza}

          botoneliminar = {botoneliminar}
          setBotonEliminar = {setBotonEliminar}

          eliminaItem = {eliminaItem}
        />
      </div>
    </div>
  )
}

export default Personal