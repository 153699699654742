import React, { useState } from 'react'
import { insertDataJson, updateItem } from '../helpers'
import Mensaje from '../Mensaje'

const Formulario = ({getPersonal, setPersonal, personal, clientes, locaciones, puestos, getLocaciones, setActualizar, actualizar}) =>{
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const {     
  nombre_completo,
  edad,
  segmento,
  status,
  puesto_id,
  locacion_id,
  cliente_id } = personal;

  const actualizaState = e =>{
    setPersonal({
      ...personal,
      [e.target.name] : e.target.value
    })

    if(e.target.name === 'cliente_id'){
      getLocaciones(e.target.value)
    }

  } 

  const reiniciarForm = (e) =>{
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Se ha guardado correctamente',
      etiqueta: 'success'
    })
    setPersonal({
      nombre_completo: '',
      edad: '',
      segmento: '',
      status: 0,
      puesto_id: 0,
      locacion_id: 0,
      cliente_id: 0
    })
    getPersonal();
  }

  const submitFormulario = async e =>{
    e.preventDefault();

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Guardando....',
      etiqueta: 'primary'
    })

    if(nombre_completo ==='' || puesto_id === 0 || locacion_id === 0 || cliente_id === 0){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Todos los campos(*) son obligatorios',
        etiqueta: 'error'
      })
      return;
    }

    let ruta = 'personal';
    if(!actualizar){
      //Guardar
      let datos = {
        nombre_completo: personal.nombre_completo,
        edad: personal.edad,
        segmento: personal.segmento,
        status: personal.status,
        puesto_id: personal.puesto_id,
        locacion_id: personal.locacion_id,
        cliente_id: personal.cliente_id
      }
      const send = await insertDataJson(ruta,datos);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===201){
        reiniciarForm();
      }
    }else{
      //Actualizar
      let datos = {
        nombre_completo: personal.nombre_completo,
        edad: personal.edad,
        segmento: personal.segmento,
        status: personal.status,
        puesto_id: personal.puesto_id,
        locacion_id: personal.locacion_id,
        cliente_id: personal.cliente_id
      }
      const send = await updateItem(ruta+'/'+personal.id,datos);
      console.log(send);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===200){
        setActualizar(false);
        const user =  await JSON.parse(localStorage.getItem('ACEROS_USER'));
        reiniciarForm();
      }
    }

  }

  return(
    <div className="col-md-5">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            PERSONAL
          </div>
        </div>
        <div className="card-body">
          { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          <form className=""
            onSubmit={submitFormulario}
          > 
            <label>Nombre completo(*)</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "nombre_completo"
              value = {nombre_completo}
              onChange = {actualizaState}
              required
              />
            <label>Edad(*)</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "edad"
              value = {edad}
              onChange = {actualizaState}
              required
              />
              <label>Cliente:</label>
              <select 
                className="form-control"
                name="cliente_id" 
                value = {cliente_id}
                onChange = {actualizaState}

                >
                <option value = "0">-- Seleccione un cliente --</option>
                {
                    clientes ?
                    clientes.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.nombre_comercial}
                      </option>
                    ))
                  :null
                }
              </select>
              <label>Locacion:</label>
              <select 
                className="form-control"
                name="locacion_id" 
                value = {locacion_id}
                onChange = {actualizaState}

                >
                <option value = "0">-- Seleccione una locacion --</option>
                {
                    locaciones ?
                    locaciones.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.nombre_locacion}
                      </option>
                    ))
                  :null
                }
              </select>
              <label>Puesto:</label>
              <select 
                className="form-control"
                name="puesto_id" 
                value = {puesto_id}
                onChange = {actualizaState}

                >
                <option value = "0">-- Seleccione un puesto --</option>
                {
                    puestos ?
                    puestos.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.desc_puesto}
                      </option>
                    ))
                  :null
                }
              </select>
              <label>Estatus:</label>
              <select 
                className="form-control"
                name="status" 
                value = {status}
                onChange = {actualizaState}

                >
                <option value = "0">Activo</option>
                <option value = "1">Inactivo</option>
              </select>
              <div style={{ marginTop: 10 }}></div>
              {
                actualizar ?
                <button sty className="mt-1 btn btn-warning">Actualizar personal</button>
                :
                <button className="mt-1 btn btn-primary">Guardar personal</button>
              }
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formulario