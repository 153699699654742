import React, { useEffect, useState, useContext } from 'react'
import { getData, updateItem } from '../helpers'
import Formulario from './Formulario'
import Tabla from './Tabla'
import { AppContext } from '../contextApp'

const Personal = () =>{  
  const { dominio } = useContext(AppContext);
  const [personals, setPersonals]= useState([]);
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [locaciones, setLocaciones] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [personal, setPersonal] = useState({
    nombre_completo: '',
    edad: '',
    segmento: '',
    status: 0,
    puesto_id: 0,
    locacion_id: 0,
    cliente_id: 0
  });
  const [personalfiltrado, setPersonalFiltrado] = useState([]);
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const [imagen, setImagen]= useState(null);

  const getPersonal = async()=>{
    const usr =  await JSON.parse(localStorage.getItem('QHSE_USER'));
    let ruta = 'personal'
    if(usr.cliente_id !== 0){
      ruta = 'personal/'+usr.cliente_id
    }
    const send = await getData(ruta);
    console.log(send);
    if(send.data){
      setPersonals(send.data);
      setPersonalFiltrado(send.data);
      return;
    }
  }

  const getPuestos = async()=>{
    const send = await getData('puesto');
    console.log(send);
    if(send.data){
      setPuestos(send.data)
      return;
    }
  }

  const getClientes = async()=>{
    const send = await getData('cliente');
    console.log(send);
    if(send.data){
      setClientes(send.data)
      return;
    }
  }

  const getLocaciones = async(id)=>{
    const send = await getData('locacion/'+id);
    console.log(send);
    if(send.data){
      setLocaciones(send.data)
      return;
    }
  }
  
  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    await getClientes();
    await getLocaciones(data.cliente_id);
    setPersonal(data);
  }

  const eliminaItem = async (data) =>{
    let suspender = {
      status: 1
    }
    const send = await updateItem('personal/'+data.id,suspender);
    console.log(send);
    if(send.code===423){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: send.error,
        etiqueta: 'error'
      })
    }
    if(send.code===200){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha suspendido el personal',
        etiqueta: 'success'
      })
      setBotonEliminar(false);
      getPersonal();
    }
  }

  const filtrarPersonal = () =>{

  }

  useEffect(()=>{
    getPersonal();
    getClientes();
    getPuestos();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <Formulario 
          getPersonal = {getPersonal}
          setPersonal = {setPersonal}
          personal = {personal}
          clientes = {clientes}
          locaciones = {locaciones}
          puestos = {puestos}

          getLocaciones = {getLocaciones} 
          getPuestos = {getPuestos}

          setActualizar = {setActualizar}
          actualizar = {actualizar}
        />
        <Tabla 
          mostrarmensaje2 = {mostrarmensaje2}

          setPersonals = {setPersonals}
          personals = {personals}

          setPersonalFiltrado = {setPersonalFiltrado}
          personalfiltrado = {personalfiltrado}
          
          setPersonal = {setPersonal}
          personal = {personal}

          actualiza = {actualiza}

          clientes = {clientes}
          getLocaciones = {getLocaciones} 
          locaciones = {locaciones}

          eliminaItem = {eliminaItem}
        />
      </div>
    </div>
  )
}

export default Personal