import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {insertData} from '../helpers'


const GraficaOtros =( { titulo, datos } )=>{
  
  const options_general = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: 300
    },
    credits: {
      enabled: false
    },
    exporting:{
      enabled: false
    },
    title: {
        enabled: true,
        text: '<b style="font-size: 15px">'+titulo+'</b>',
        align: 'center',
        verticalAlign: 'top',
        y: 10
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
          showInLegend: true,
        }
    },
    series: [{
        name: 'Total',
        innerSize: '70%',
        data: datos
    }]
  }
  

  return(

    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={options_general}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraficaOtros