import React, { useEffect, useState, useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { AppContext } from './contextApp'

import Header from './Template/Header'
import Sidebar from './Template/Sidebar'
import Footer from './Template/Footer'

import Dashboard from './Dashboard/Dashboard'
import DashboardCliente from './Dashboard/DashboardCliente'
import Puestos from './Puestos/Puestos'
import Personal from './Personal/Personal';
import Asistencia from './Asistencia/Asistencia'
import Usuarios from './Usuarios/Usuarios';


const Main = (props) => {
  const { ruta, setRuta } = useContext(AppContext);
  const [active, setActive] = useState(0);
  const [despliega, setDespliega] = useState(0);

// const validar = async () =>{
//   const isAuthenticated = await getCookie('STORAGE_KEY');
//   if(!isAuthenticated){
//     props.history.push("/login");
//   }
// }

  useEffect(() => { 

//    validar();

    if(window.location.pathname==="/dashboard"){
      setActive(0)
      setRuta('Dashboard');
    }else if(window.location.pathname==="/puestos"){
      setActive(1)
      setRuta('Puestos');
    }else if(window.location.pathname==="/personal"){
      setActive(2)
      setRuta('Personal');
    }else if(window.location.pathname==="/asistencia"){
      setActive(3)
      setRuta('Asistencia');
    }else if(window.location.pathname==="/usuarios"){
        setActive(4)
        setRuta('Usuarios');
    }else if(window.location.pathname==="/"){
      setRuta('Dashboard');
    }else if(window.location.pathname==="/dashboard-cliente"){
      setRuta('Dashboard');
    }
}, [])


  return (
    <Router>
      <Sidebar 
        active = {active}
      />
        <main class="main-content mt-1 border-radius-lg">
            <Header ruta = { ruta } />
            <div className="app-main"> 
                <div className="app-main__outer">
                    <Switch>
                      <Route path="/usuarios" component={Usuarios} />
                      <Route path="/asistencia" component={Asistencia} />
                      <Route path="/personal" component={Personal} />
                      <Route path="/puestos" component={Puestos} />
                      <Route path="/dashboard-cliente" component={DashboardCliente} />
                      <Route path="/dashboard" component={Dashboard} />
                      <Route path="/" component={Dashboard} />
                    </Switch>
                  <Footer />
                </div>
            </div>
        </main>
    </Router>
  );
}

export default Main;
