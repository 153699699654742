import React, { Fragment, useState, useEffect} from 'react';
import { login } from '../helpers'
import Mensaje from '../Mensaje'
import { setCookie } from './AuthenticatedComponent'


const ContainerLogin=props=>{
  const [user, actualizaUser] = useState({
      email:'',
      password: ''
  })

  //Extraer valores
  const {email, password} = user;

  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });

  const {message, label} = mensaje;

  const cerrarSesion= async()=>{
    //const cerrar =  await logout();
    document.cookie = "STORAGE_KEY=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem('QHSE_USERTOKEN');
    localStorage.removeItem('QHSE_USER');
  }

  useEffect(()=>{
    cerrarSesion();
  },[]);

  const actualizaState = e =>{
    actualizaUser({
      ...user,
      [e.target.name] : e.target.value
    })
  }

  const submitLogin = async e =>{
    e.preventDefault();
    
    const sendUser = await login('login',user);
    console.log(sendUser);
    if(sendUser.user){
      if(sendUser.user.admin === 'false' && parseInt(sendUser.user.jefe_grupo) !==0){
          actualizaMensaje({
            status: true,
            label: "error",
            message: "No tiene permisos para acceder"
          })
        return;
      }
      localStorage.setItem('QHSE_USERTOKEN',sendUser.access_token);
      const user = JSON.stringify(sendUser.user);
      localStorage.setItem('QHSE_USER',user);
      actualizaMensaje({
        status:true,
        label: "primary",
        message: 'Iniciando....'
      })
      setCookie('STORAGE_KEY', sendUser.access_token);
      if(sendUser.user.admin === 'true' && parseInt(sendUser.user.cliente_id)===0){
        window.location = '/panel';
        return;
      }
      if(parseInt(sendUser.user.cliente_id)!==0 && parseInt(sendUser.user.jefe_grupo)===0){
        window.location = '/dashboard-cliente';
        return;
      }
    }

    actualizaMensaje({
      status:true,
      label: "error",
      message: sendUser.message
    })

    window.location = '/login';
  }

  return(
    <Fragment>
      <div class="container position-sticky z-index-sticky top-0">
      </div>
      <section>
        <div class="page-header section-height-75">
          <div class="container">
            <div class="row">
              <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                <div class="card card-plain mt-8">
                  <div class="card-header pb-0 text-left bg-transparent">
                    <h3 class="font-weight-bolder text-info text-gradient">Bienvenido a SIPPSA</h3>
                    <p class="mb-0">Acceso solo para personal autorizado</p>
                  </div>
                  <div class="card-body">
                    <form role="form text-left"
                      onSubmit={submitLogin}
                    >
                      <label>Correo:</label>
                      <div class="mb-3">
                        <input id="email" type="email" placeholder="Correo" className="form-control col-md-12" name="email" value={email} onChange={actualizaState}  required/>
                      </div>
                      <label>Contraseña:</label>
                      <div class="mb-3">
                        <input id="password" placeholder="Contraseña" type="password" className="form-control col-md-12" name="password" value={password} onChange={actualizaState}  required  />
                      </div>
                      <div class="mb-3">
                          {mensaje.status ? <Mensaje etiqueta={label} mensaje={message} />: null}
                        </div>
                      <div class="text-center">
                        <button type="submit" class="btn bg-gradient-info w-100 mt-4 mb-0">Iniciar sesión</button>
                      </div>
                    </form>
                  </div>
                  {/* <div class="card-footer text-center pt-0 px-lg-2 px-1">
                    <p class="mb-4 text-sm mx-auto">
                      ¿Olvidadaste la contraseña?
                      <a href="javascript:;" class="text-info text-gradient font-weight-bold">Sign up</a>
                    </p>
                  </div> */}
                </div>
              </div>
              <div class="col-md-6">
                <div class="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                  <div class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style= {{ backgroundImage: 'url(../../../assets/img/curved-images/curved6.jpg)' }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="footer py-4 fixed-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 mb-4 mx-auto text-center">
              <a href="https://sippsa.mx" target="_blank" class="text-secondary me-xl-5 me-3 mb-sm-0 mb-2">
                Sippsa.mx | Todos los derechos reservados.
              </a>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  )
}

export default ContainerLogin

