import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const GraficaLocacionBarras =( { datos, nombre } )=>{
  let lista_filtrada_incidentes = [];
  let lista_filtrada_horas_ronda = [];
  let lista_filtrada_num_rondas = [];
  var num_incidentes= 0;
  var horas_inversion = 0;
  var total_rondas = 0;

  let list_obervaciones_criticas = [];
  let num_criticos = 0;

  if(datos.observaciones_criticas){
    list_obervaciones_criticas = datos.observaciones_criticas.filter(item => item.nombre_locacion === nombre);
    if(list_obervaciones_criticas[0]){
      num_criticos = list_obervaciones_criticas[0].total;
    }
  }

  if(datos.total_incidentes){
    lista_filtrada_incidentes = datos.total_incidentes.filter(item => item.nombre_locacion === nombre);
    num_incidentes = lista_filtrada_incidentes.reduce((sum, value) => ( sum + parseInt(value.total_incidentes) ), 0);
  }
  if(datos.horas_inversion){
    lista_filtrada_horas_ronda = datos.horas_inversion.filter(item => item.nombre_locacion === nombre);
    horas_inversion = lista_filtrada_horas_ronda.reduce((sum, value) => ( sum + parseInt(value.horas_rondas_inversion) ), 0);
    horas_inversion = (horas_inversion/60).toFixed(2)
  }

  if(datos.total_rondas){
    lista_filtrada_num_rondas = datos.total_rondas.filter(item => item.nombre_locacion === nombre);
    total_rondas = lista_filtrada_num_rondas.reduce((sum, value) => ( sum + parseInt(value.total_rondas) ), 0);
  }

  const options_general = {  
    chart: {
      type: 'bar',
      height: 120
    },
    credits: {
      enabled: false
    },
    title: {
        text: ''
    },
    xAxis: {
        categories: ['Críticos','No. Incidentes', 'Horas rondas', 'No. Rondas'],
        title: {
            text: null
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: '',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        }
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true
            }
        }
    },
    credits: {
        enabled: false
    },
    legend: {
      enabled: false
    },
    series: {
      name: 'Total',
      data: [{
        y: parseFloat(num_criticos),
        color: 'red'
      },
      {
        y: parseFloat(num_incidentes),
        color: '#96d74b'
      },
      {
        y: parseFloat(horas_inversion),
        color: '#96d74b'
      },
      {
        y: parseFloat(total_rondas),
        color: '#96d74b'
      }]
    }
  }
  

  return(

    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={options_general}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraficaLocacionBarras